import { Input, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { convertStringToNumber } from 'utils/convertStringToNumber';

import s from './RangeFilter.module.scss';

type RangeFilterProps = {
  from: number | null;
  to: number | null;
  valueMin: number | null;
  valueMax: number | null;
  onChangeMin: (v: number | null) => void;
  onChangeMax: (v: number | null) => void;
  placeholderPostfix?: string;
  className?: string;
  placeholderSufix?: {
    from: string;
    to: string;
  };
  placeholderTo?: number;
  seporatorHide?: boolean;
  flat?: boolean;
  size?: 'default' | 'big';
  offFilter?: () => void;
  preventNonInteger?: boolean;
  allowNegative?: boolean;
};

const RangeFilter = ({
  from,
  to,
  valueMin,
  valueMax,
  onChangeMin,
  onChangeMax,
  placeholderPostfix,
  className,
  placeholderSufix = {
    from: '',
    to: '',
  },
  placeholderTo,
  seporatorHide = false,
  flat = false,
  size = 'default',
  offFilter,
  preventNonInteger,
  allowNegative,
}: RangeFilterProps): React.ReactElement<RangeFilterProps> => {
  const invalidNumericCharacters = React.useRef(
    allowNegative ? ['.', ',', '+', 'e', 'E'] : ['.', ',', '+', 'e', 'E', '-'],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!preventNonInteger) return;
    if (invalidNumericCharacters.current.includes(e.key)) e.preventDefault();
    if (!allowNegative) return;
    if (!invalidNumericCharacters.current.includes('-')) {
      invalidNumericCharacters.current.push('-');
    }
    if (invalidNumericCharacters.current.includes('-') && e.key === 'Backspace' && e.currentTarget.value.length <= 1) {
      invalidNumericCharacters.current = invalidNumericCharacters.current.filter((char) => char !== '-');
    }
  };

  const handleChangeMin = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMin(convertStringToNumber(e.target.value));
    },
    [onChangeMin],
  );

  const handleChangeMax = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMax(convertStringToNumber(e.target.value));
    },
    [onChangeMax],
  );

  return (
    <div className={cn(flat ? s['range-filter-flat'] : s['range-filter'], className)}>
      <div className={s.wrap__input}>
        {offFilter && <div className={s.offFilter} onClick={offFilter}></div>}
        <Input
          className={cn(
            s['range__input__' + size],
            flat ? s['range-filter__input__flat'] : s['range-filter__input'],
            flat && s.border__right,
          )}
          placeholder={placeholderSufix.from}
          type="number"
          value={valueMin ?? undefined}
          onChange={handleChangeMin}
          min={from || undefined}
          max={to || undefined}
          suffix={placeholderPostfix ? <div className={s.sp}>{placeholderPostfix}</div> : undefined}
          disabled={Boolean(offFilter)}
          onKeyDown={handleKeyDown}
        />
      </div>

      {!seporatorHide && <Typography.Text className={s['range-filter__dash']}>–</Typography.Text>}
      <div className={s.wrap__input}>
        {offFilter && <div className={s.offFilter} onClick={offFilter}></div>}
        <Input
          className={cn(s['range__input__' + size], flat ? s['range-filter__input__flat'] : s['range-filter__input'])}
          placeholder={placeholderSufix.to}
          type="number"
          value={valueMax ?? undefined}
          onChange={handleChangeMax}
          min={from || undefined}
          max={to || undefined}
          suffix={placeholderPostfix ? <div className={s.sp}>{placeholderPostfix}</div> : undefined}
          disabled={Boolean(offFilter)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default React.memo(RangeFilter);

import React, { ReactNode, useContext, useEffect } from 'react';
import { ModalText, ModalTextFilter } from './ModalText';

type ModalTextContextProps = {
  msg: string | null;
  changeMsg: (msg: string | null) => void;
  open: boolean;
  toggleOpen: (value: boolean) => void;
};

const ModalTextContext = React.createContext<ModalTextContextProps>({
  msg: null,
  open: false,
  toggleOpen: (value: boolean) => void 0,
  changeMsg: (msg: string | null) => void 0,
});

const ModalTextFilterContext = React.createContext<ModalTextContextProps>({
  msg: null,
  open: false,
  toggleOpen: (value: boolean) => void 0,
  changeMsg: (msg: string | null) => void 0,
});

export const ModalTextProvider = ({ children }: { children: ReactNode }) => {
  const [msg, setMsg] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <ModalTextContext.Provider
      value={{
        msg,
        changeMsg: setMsg,
        toggleOpen: setOpen,
        open,
      }}
    >
      {children}
      <ModalText />
    </ModalTextContext.Provider>
  );
};

export const ModalTextFilterProvider = ({ children }: { children: ReactNode }) => {
  const [msg, setMsg] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <ModalTextFilterContext.Provider
      value={{
        msg,
        changeMsg: setMsg,
        toggleOpen: setOpen,
        open,
      }}
    >
      {children}
      <ModalTextFilter />
    </ModalTextFilterContext.Provider>
  );
};

export const useModalFilterText = (args?: { text?: string; onCancel?: () => void; onEnter?: () => void }) => {
  const ctx = useContext(ModalTextFilterContext);
  useEffect(() => {
    if (args) {
      ctx?.changeMsg(args.text || null);
    }
  }, []);

  useEffect(() => {
    if (!ctx.open && args) {
      ctx?.changeMsg(args.text || null);
    }
  }, [ctx.open]);

  return {
    ...ctx,
    ...args,
  };
};

export const useModalText = (args?: { text?: string; onCancel?: () => void; onEnter?: () => void }) => {
  const ctx = useContext(ModalTextContext);
  useEffect(() => {
    if (args) {
      ctx?.changeMsg(args.text || null);
    }
  }, []);

  return {
    ...ctx,
    ...args,
  };
};

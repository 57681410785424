import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import bannerImg from 'assets/services/bn-legal.png';
import img1 from 'assets/services/s1.png';
import img2 from 'assets/services/s2.png';
import img3 from 'assets/services/s3.png';
import img4 from 'assets/services/s5.png';
import { PageContainer } from 'components/PageContainer';
import { PdfIcon } from 'components/icons/PdfIcon';
import { ServicesSlugStatic } from 'entities/services/services';
import { BannerPage } from 'pages/ServicesInfoPage/components/Banner/BannerPage';
import { CardStep } from 'pages/ServicesInfoPage/components/CardStep/CardStep';
import { FaqItem } from 'pages/ServicesInfoPage/components/FaqItem/FaqItem';
import { SliderStep } from 'pages/ServicesInfoPage/components/SliderStep/SliderStep';
import { ServicesStoreProvider } from 'stores/ServicesStore/context';

import s from './AuditLegalPage.module.scss';

const BREAKPOINTS = {
  0: {
    spaceBetween: 0,
    slidesPerView: 1,
  },
  575: {
    spaceBetween: 5,
    slidesPerView: 2.2,
  },
  768: {
    spaceBetween: 10,
    slidesPerView: 2.8,
  },
  850: {
    spaceBetween: 10,
    slidesPerView: 3.1,
  },
  1050: {
    spaceBetween: 55,
    slidesPerView: 3.5,
  },
  1280: {
    spaceBetween: 55,
    slidesPerView: 4,
  },
};

const ServisesInfoAuditLegalPage = () => {
  return (
    <ServicesStoreProvider>
      <PageContainer className={cn(s['lot-page'], s['lot-page-mobile'])}>
        <BannerPage
          bannerImg={bannerImg}
          title={`Юридический аудит объектов\nнедвижимости`}
          description={`Защитите себя от покупки проблемного объекта`}
          price={''}
          serviceSlug={ServicesSlugStatic.audit_legal}
          printLabelPrice
        />
      </PageContainer>
      <PageContainer className={cn(s['lot-page'])}>
        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Что включает юридический аудит
          </Typography.Title>
          <ul className={s.list + ' ' + s.list_max}>
            <li className={s.list__item}>
              Проверка документации по объекту: владелец, кадастровый номер, форма собственности
            </li>
            <li className={s.list__item}>Анализ обременений: залог, арест, ипотека, аренда и т.д.</li>
            <li className={s.list__item}>Соответствие БТИ и наличие неузаконенных перепланировок</li>
            <li className={s.list__item}>Резюме по объекту с выводами и рекомендациями юристов по недвижимости</li>
          </ul>
        </div>
        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Как это работает
          </Typography.Title>
          <SliderStep
            slidesPerView={4}
            spaceBetween={55}
            breakpoints={BREAKPOINTS}
            slideClass={s.slide}
            slides={[
              <CardStep
                item={{
                  title: (
                    <span>
                      Анализ объекта
                      <br />и сбор данных
                    </span>
                  ),
                  step: 1,
                  image: img1,
                }}
              />,
              <CardStep
                item={{
                  title: (
                    <span>
                      Подготовка
                      <br />и проверка
                      <br />
                      документов
                    </span>
                  ),
                  step: 2,
                  image: img2,
                }}
              />,
              <CardStep
                item={{
                  title: (
                    <span>
                      Оценка
                      <br />
                      юридических
                      <br />
                      рисков
                    </span>
                  ),
                  step: 3,
                  image: img3,
                }}
              />,
              <CardStep
                item={{
                  title: (
                    <span>
                      Подготовка
                      <br />
                      заключения
                    </span>
                  ),
                  step: 4,
                  image: img4,
                }}
              />,
            ]}
          />
        </div>

        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Преимущества
          </Typography.Title>
          <ul className={s.list + ' ' + s.list_max}>
            <li className={s.list__item}>Быстро: комплексная проверка от 1 до 5 дней</li>
            <li className={s.list__item}>Экспертно: проверку проводят юристы из строительно-инвестиционной отрасли</li>
            <li className={s.list__item}>
              Персонально: все дополнительные вопросы и особенности проверки с вами обсудит личный менеджер
            </li>
          </ul>
        </div>

        <div className={s.section + ' ' + s.space}>
          <Typography.Title className={s.title} level={2}>
            Примеры юридического аудита от КупиЛот
          </Typography.Title>
          <div className={s.center}>
            <a href={'/docs/audit'} target="_blank" rel="noreferrer">
              <PdfIcon />
            </a>
          </div>
        </div>

        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Частые вопросы (FAQ):
          </Typography.Title>
          <div className={s.padding}>
            <FaqItem
              title="Что включает в себя юридический аудит?"
              description="Мы проверяем право собственности, обременения, юридическую чистоту сделки, соответствие БТИ и коммуникации, а также готовим рекомендации по устранению рисков​"
            />
            <FaqItem
              title="Какие объекты проверяются в рамках юридического аудита?"
              description={`Мы проводим юридическую проверку различных типов недвижимости: квартиры, коммерческая недвижимость, земельные участки, а также объекты, выставленные на торги.`}
            />
            <FaqItem
              title="Как долго выполняется юридический аудит?"
              description={`Срок выполнения зависит от сложности объекта и занимает от 1 до 5 рабочих дней.`}
            />
            <FaqItem
              title="Какие документы нужны для проведения аудита?"
              description="Необходимы документы, подтверждающие право собственности, выписка из ЕГРН, кадастровый план и любые доступные данные по объекту."
            />
            <FaqItem
              title="Как получить юридическое заключение?"
              description="После проведения аудита вы получите полный отчет, включая рекомендации, в формате PDF, который можно скачать из личного кабинета."
            />
          </div>
        </div>
      </PageContainer>
    </ServicesStoreProvider>
  );
};

export default observer(ServisesInfoAuditLegalPage);

import { observer } from 'mobx-react-lite';
import React from 'react';
import prize from 'assets/prize.png';
import s from './RegistrationSuccessStep.module.scss';

export const RegistrationSuccessStep = observer(() => {
  return (
    <div className={s.content}>
      <img className={s.content__img} src={prize} alt="" />
      <h4 className={s.content__title}>Максимум в подарок!</h4>
      <p className={s.content__text}>
        Вам доступен бесплатный пробный период <br /> на 7 дней
      </p>
    </div>
  );
});

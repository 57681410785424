import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input, Select, Spin, Typography } from 'antd';
import * as React from 'react';
import cn from 'classnames';

import { FilterOption } from 'entities/lotFilters';
import { pluralizePreselected } from 'utils/pluralizer';

import { MultiSelectItem } from './MultiSelectItem';
import { SelectedItem } from './SelectedItem';

import s from './MultiSelect.module.scss';
import { observer } from 'mobx-react-lite';
import { ArrowStockIcon } from 'components/icons/ArrowStockIcon';
import { useWidth } from 'hooks/useWidth';

type MultiSelectProps<T = number> = {
  options: FilterOption<T>[];
  selectedOptions: FilterOption<T>[];
  value: FilterOption<T>['id'][];
  onChange: (id: T, data?: FilterOption<T>) => void;
  isOptionsLoading: boolean;
  withSearch?: boolean;
  onSearch?: (value: string) => void;
  searchValue?: string;
  className?: string;
  placeholder?: string;
  hideSelectedList?: boolean;
  withActions?: boolean;
  dropdownStyle?: React.CSSProperties;
  dropdownMulti?: boolean;
  onSelectedAllCustom?: () => void;
  onClearSelectedCustom?: () => void;
  defaultOpen?: boolean;
  dropdownSelectsClass?: string;
  actionsDropdownClass?: string;
  searchInputFilter?: RegExp;
  offSelect?: () => void;
};

const MultiSelect = <T,>({
  options,
  selectedOptions,
  value,
  onChange,
  isOptionsLoading,
  withSearch = true,
  onSearch,
  searchValue,
  className,
  placeholder = 'Не выбрано',
  hideSelectedList = false,
  withActions,
  dropdownStyle = {},
  dropdownMulti = false,
  onClearSelectedCustom,
  onSelectedAllCustom,
  defaultOpen,
  dropdownSelectsClass = '',
  actionsDropdownClass = '',
  searchInputFilter,
  offSelect,
}: MultiSelectProps<T>): React.ReactElement<MultiSelectProps<T>> => {
  const keyId = React.useId();
  const [more, setMore] = React.useState(false);
  const [isSelectedAll, setSelectedAll] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const { isDesktop } = useWidth();
  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (searchInputFilter) {
        if (searchInputFilter.test(e.target.value[e.target.value.length - 1])) {
          setSearch(e.target.value);
          onSearch?.(e.target.value);
        }
      } else {
        setSearch(e.target.value);
        onSearch?.(e.target.value);
      }
    },
    [onSearch],
  );

  const onSelectedAll = () => {
    if (onSelectedAllCustom) {
      onSelectedAllCustom();
    } else {
      if (!isOptionsLoading) {
        options.forEach((item) => {
          onChange(item.id);
        });
      }
    }
  };
  const onClearSelected = () => {
    if (onClearSelectedCustom) {
      onClearSelectedCustom();
    } else {
      if (!isOptionsLoading) {
        options.forEach((item) => {
          if (value.includes(item.id)) {
            onChange(item.id);
          }
        });
      }
    }
  };

  const onToggleSelectedAll = () => {
    if (isSelectedAll) {
      onClearSelected();
    } else {
      onSelectedAll();
    }
    setSelectedAll((prev) => !prev);
  };

  const selectedOptionsSplit = React.useMemo(() => {
    if (selectedOptions.length > 4) {
      return [selectedOptions.slice(0, 4), selectedOptions.slice(4, selectedOptions.length - 1)];
    }
    return [selectedOptions];
  }, [selectedOptions]);

  React.useEffect(() => {
    setMore(false);
  }, [selectedOptions]);

  return (
    <div className={className + ' ' + s.wrap}>
      {offSelect && <div className={s.offSelect} onClick={offSelect}></div>}
      <Select
        className={s['multi-select__select']}
        dropdownStyle={{
          borderRadius: '18px',
          ...dropdownStyle,
        }}
        suffixIcon={<ArrowStockIcon />}
        disabled={offSelect !== undefined}
        dropdownRender={() => (
          <>
            {withSearch && (
              <Input
                className={s['multi-select__search']}
                placeholder="Поиск"
                value={search}
                onChange={handleSearch}
                suffix={isDesktop ? <SearchOutlined className={s['multi-select__search-icon']} /> : undefined}
                prefix={!isDesktop ? <SearchOutlined className={s['multi-select__search-icon']} /> : undefined}
                allowClear={false}
              />
            )}
            {withActions && (
              <div className={s.actions + ' ' + actionsDropdownClass}>
                {/* <button className={s.btn} onClick={onSelectedAll}> */}
                <Checkbox
                  className={cn(s['multi-select-item'], className, s.checkbox)}
                  checked={isSelectedAll}
                  onChange={onToggleSelectedAll}
                >
                  Выбрать все
                </Checkbox>

                <button className={s.btn} onClick={onClearSelected}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_722_14591)">
                      <circle cx="8" cy="8" r="8" fill="#E5E5E5" />
                      <path
                        d="M11.5968 5.06314L8.66075 8.00005L11.5968 10.937C11.7788 11.119 11.7788 11.4149 11.5968 11.597C11.5054 11.6885 11.3859 11.7333 11.2665 11.7333C11.147 11.7333 11.0275 11.6875 10.9361 11.597L7.99999 8.66006L5.06391 11.597C4.97245 11.6885 4.85299 11.7333 4.73353 11.7333C4.61407 11.7333 4.49461 11.6875 4.40315 11.597C4.22117 11.4149 4.22117 11.119 4.40315 10.937L7.33924 8.00005L4.40315 5.06314C4.22117 4.8811 4.22117 4.58517 4.40315 4.40313C4.58514 4.22109 4.88099 4.22109 5.06298 4.40313L7.99906 7.34004L10.9351 4.40313C11.1171 4.22109 11.413 4.22109 11.595 4.40313C11.777 4.58517 11.777 4.8811 11.595 5.06314H11.5968Z"
                        fill="#252525"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_722_14591">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Сбросить
                </button>
              </div>
            )}
            <div
              className={`${s['multi-select__items-wrapper']} ${dropdownMulti ? s.left : ''} ${dropdownSelectsClass} multi-select__selected-items`}
              data-class="multi-select__selected-items-wrapper"
            >
              {isOptionsLoading && <Spin size="small" />}
              {!isOptionsLoading && options.length === 0 && (
                <Typography.Text className={s['multi-select__item_placeholder']}>Нет доступных опций</Typography.Text>
              )}
              {!isOptionsLoading &&
                options.map((option) => (
                  <MultiSelectItem
                    key={option.id + '-' + keyId}
                    option={option}
                    onChange={onChange}
                    checked={value.includes(option.id)}
                    selected={value}
                    className={s['multi-select__item']}
                    dropdownMulti={dropdownMulti}
                  />
                ))}
            </div>
          </>
        )}
        placeholder={placeholder}
        value={
          selectedOptions.length > 0 ? `Выбрано ${pluralizePreselected(selectedOptions.length, 'значение')}` : null
        }
        open={defaultOpen}
      />

      {selectedOptions.length > 0 && !hideSelectedList && (
        <div className={s['multi-select__selected-items-wrapper']}>
          {selectedOptionsSplit[0].map((option) => (
            <SelectedItem
              className={s.selected__item}
              key={option.id as any}
              option={option}
              onChangeItem={onChange as any}
            />
          ))}
          {!more && selectedOptionsSplit[1] && selectedOptionsSplit[1].length > 0 && (
            <div
              className={s.selected__item + ' ' + s.more}
              onClick={() => {
                setMore(true);
              }}
            >
              Показать больше
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                  d="M7.00039 10C6.68501 10 6.38776 9.87864 6.16384 9.65847L2 5.55002L2.55744 5L6.72128 9.10845C6.8703 9.25549 7.1297 9.25549 7.27872 9.10845L11.4426 5L12 5.55002L7.83616 9.65847C7.61303 9.87864 7.31578 10 6.99961 10H7.00039Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          )}
          {more &&
            selectedOptionsSplit[1] &&
            selectedOptionsSplit[1].map((option) => (
              <SelectedItem
                className={s.selected__item}
                key={option.id as any}
                option={option}
                onChangeItem={onChange as any}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default observer(MultiSelect);

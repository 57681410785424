import { Menu } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useMenuKeys } from 'hooks/useMenuKeys';
import { useRootStore } from 'stores/RootStore';

import { MENU_ITEMS } from './config';

import s from './HeaderMenu.module.scss';
import { observer } from 'mobx-react-lite';
import { ArrowStockIcon } from 'components/icons/ArrowStockIcon';
import { useWidth } from 'hooks/useWidth';

type HeaderMenuProps = {
  className?: string;
  mode?: 'horizontal' | 'vertical' | 'inline';
  inlineIndent?: number;
};

const HeaderMenu = ({
  className,
  mode = 'horizontal',
  inlineIndent = 24,
}: HeaderMenuProps): React.ReactElement<HeaderMenuProps> => {
  const { authStore, userStore } = useRootStore();
  const selectedKeys = useMenuKeys();
  const { width = window.innerWidth } = useWidth();
  const handleMenuClick = (e: React.MouseEvent, item: (typeof MENU_ITEMS)[number]) => {
    if (item.isAuthRequired && !userStore.authorized) {
      e.preventDefault();
      e.stopPropagation();
      authStore.popupController.open();
      authStore.mode.change('login');
    }
  };

  const items = MENU_ITEMS.map((item) => ({
    key: item.url,
    popupOffset: [-70, 0],
    label: item.cheildren ? (
      <span className={s.title__mune__arrow} onClick={(e) => handleMenuClick(e, item)}>
        {item.title} <ArrowStockIcon />
      </span>
    ) : (
      <Link to={item.url} onClick={(e) => handleMenuClick(e, item)}>
        {item.title}
      </Link>
    ),
    children: item.cheildren
      ? item.cheildren.map((itemChild) => ({
          key: itemChild.key,
          label: (
            <Link to={itemChild.url} onClick={(e) => handleMenuClick(e, item)}>
              {itemChild.label}
            </Link>
          ),
        }))
      : undefined,
  }));

  if (userStore.authorized && width < 1280) {
    items.push({
      key: 'logout',
      label: (
        <button
          className={s.logout}
          onClick={() => {
            authStore.logout();
          }}
        >
          <span className={cn(s['profile-menu__logout-text'])}>Выйти</span>
        </button>
      ),
      children: undefined,
    } as any);
  }

  return (
    <Menu
      className={cn(s['header-menu'], className)}
      selectedKeys={selectedKeys}
      mode={mode}
      items={items}
      onClick={(e) => console.log(e)}
      defaultSelectedKeys={['#']}
      inlineIndent={inlineIndent}
      rootClassName={s.root__menu}
    />
  );
};

export default observer(HeaderMenu);

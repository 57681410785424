import * as React from 'react';
import { Outlet } from 'react-router-dom';

import AuthPopup from 'components/AuthPopup';
import EmailPopup from 'components/EmailPopup';
import { useScrollToTop } from 'hooks/useScrollTop';
import { WidthProvider } from 'hooks/useWidth';
import { RootStoreContextProvider } from 'stores/RootStore';

import s from './App.module.scss';
import { BugReport } from 'components/BugReport/BugReport';
import { CookiesContextProvider } from 'components/Cookies';
import { ModalTextProvider, ModalTextFilterProvider } from 'components/ModalText/ModalText.context';
import { RouteFullPath } from 'config/routes';
import { HeaderFlat } from 'components/HeaderFlat';
import { FooterLight } from 'components/FooterLight';

const App: React.FC = () => {
  useScrollToTop();
  const isHome = window.location.pathname === RouteFullPath.home;
  return (
    <RootStoreContextProvider>
      <ModalTextFilterProvider>
        <ModalTextProvider>
          <CookiesContextProvider>
            <WidthProvider>
              <div className={s.app__container}>
                <HeaderFlat offset={!isHome} />
                <Outlet />
                <FooterLight />
              </div>
              {!isHome && <BugReport />}
              <AuthPopup />
              <EmailPopup />
              {/* <Onboarding /> */}
            </WidthProvider>
          </CookiesContextProvider>
        </ModalTextProvider>
      </ModalTextFilterProvider>
    </RootStoreContextProvider>
  );
};

export default App;

import { Popover } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { RangeFilter } from 'components/RangeFilter';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

import s from './PriceSelect.module.scss';

type PriceProps = {
  className?: string;
};

const PriceSelect = ({}: PriceProps): React.ReactElement<PriceProps> => {
  const { filters } = useLotListStore();
  const [open, setOpen] = React.useState(false);
  const placeholder = React.useMemo(() => {
    if (filters.price.valueMax.value && filters.price.valueMin.value) {
      return filters.price.valueMin.value + ' - ' + filters.price.valueMax.value;
    }

    return 'Цена';
  }, [filters.price.valueMax.value, filters.price.valueMin.value]);
  return (
    <div>
      <div
        className={cn(s.select, open && s.open, placeholder !== 'Цена' && s.text)}
        role="button"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span>{placeholder}</span>
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.8932 0.571442H9.88873C9.82043 0.571442 9.75614 0.604924 9.71596 0.659835L5.91105 5.90448L2.10614 0.659835C2.06596 0.604924 2.00167 0.571442 1.93337 0.571442H0.928907C0.841853 0.571442 0.79096 0.670549 0.841853 0.741531L5.56418 7.2518C5.7356 7.48751 6.0865 7.48751 6.25659 7.2518L10.9789 0.741531C11.0311 0.670549 10.9802 0.571442 10.8932 0.571442Z"
            fill="black"
            fillOpacity="0.25"
          />
        </svg>
      </div>
      <Popover
        overlayClassName={s.popoverNoPadding}
        placement="bottom"
        content={
          <div>
            <RangeFilter
              from={filters.price.from.value}
              to={filters.price.to.value}
              valueMin={filters.price.valueMin.value}
              valueMax={filters.price.valueMax.value}
              onChangeMin={filters.price.valueMin.change}
              onChangeMax={filters.price.valueMax.change}
              placeholderPostfix="₽"
              placeholderTo={filters.price.valueMax.value || 0}
              preventNonInteger
            />
          </div>
        }
        arrow={false}
        open={open}
        onOpenChange={setOpen}
      ></Popover>
    </div>
  );
};

export default observer(PriceSelect);

import React from 'react';
import { Button, Modal } from 'antd';
import { useModalFilterText, useModalText } from './ModalText.context';
import s from './ModalText.module.scss';
import { useRootStore } from 'stores/RootStore';
import { AnalyticsEvent } from 'entities/analytics';
import { useNavigate } from 'react-router-dom';
import { RouteSegment } from 'config/routes';

export const ModalText = () => {
  const { open, toggleOpen, msg } = useModalText();
  const { analyticsStore, authStore } = useRootStore();

  const onClose = () => {
    toggleOpen(false);
    authStore.mode.change('login');
  };

  const handleLoginButtonClick = React.useCallback(() => {
    onClose();
    authStore.popupController.open();
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const handleSiginButtonClick = () => {
    onClose();
    authStore.mode.change('register');
    authStore.popupController.open();
  };

  return (
    <Modal
      className={s.modal__popup}
      open={open}
      footer={null}
      closeIcon={null}
      onCancel={onClose}
      width={416}
      mask={false}
      centered
    >
      <div className={s.modal}>
        <p className={s.modal__text}>{msg}</p>
        <div className={s.actions}>
          <Button type="primary" onClick={handleSiginButtonClick} className={s.btn__auth}>
            Зарегистрироваться
          </Button>
          <Button type="link" onClick={handleLoginButtonClick}>
            Войти
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ModalTextFilter = () => {
  const { open, toggleOpen, msg } = useModalFilterText({
    text: 'Для использования всех возможностей фильтров и поиска оформите тариф',
  });
  const navigation = useNavigate();
  const onClose = () => {
    toggleOpen(false);
  };

  const handleLoginButtonClick = () => {
    onClose();
  };

  const handleSiginButtonClick = () => {
    onClose();
    navigation(RouteSegment.tariffs);
  };

  return (
    <Modal
      className={s.modal__popup}
      wrapClassName={s.modal__wrap}
      open={open}
      footer={null}
      closeIcon={null}
      onCancel={onClose}
      width={416}
      mask={false}
      centered
    >
      <div className={s.modal}>
        <p className={s.modal__text}>{msg}</p>
        <div className={s.actions}>
          <Button type="primary" onClick={handleSiginButtonClick} className={s.btn__auth}>
            Изучить тарифы
          </Button>
          <Button type="link" onClick={handleLoginButtonClick}>
            Остаться
          </Button>
        </div>
      </div>
    </Modal>
  );
};

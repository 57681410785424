import * as React from 'react';

export const useKeyboardEvent = (key: string, callback: (...args: any) => void, shouldSubscribe = true) => {
  React.useEffect(() => {
    if (!shouldSubscribe) {
      return;
    }

    const cb = (e: KeyboardEvent) => {
      if (e.key === key) {
        callback();
      }
    };

    document.addEventListener('keydown', cb);

    return () => {
      document.removeEventListener('keydown', cb);
    };
  }, [key, callback, shouldSubscribe]);
};

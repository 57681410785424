import { Typography } from 'antd';
import { PageContainer } from 'components/PageContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import s from '../../../StaticPages/StaticPages.module.scss';

const OnlinePublicOfferPage = observer(() => {
  return (
    <PageContainer>
      <div className={s.center__text}>
        <Typography.Title level={3}>ДОГОВОР (ОФЕРТА)</Typography.Title>
        <Typography.Title level={3} className={s.title__agree}>
          на оказание услуг по просмотру объекта
        </Typography.Title>
      </div>

      <Typography.Title level={3}>1 ОБЩИЕ ПОЛОЖЕНИЯ</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          1.1 Настоящий документ является предложением (далее - Оферта) общества с ограниченной ответственностью
          «КУПИЛОТ» (ООО «КУПИЛОТ»), именуемого в дальнейшем - «Исполнитель», в лице генерального директора Самойлова
          Александра Андреевича, действующего на основании Устава, физическому лицу, юридическому лицу или
          индивидуальному предпринимателю, именуемому в дальнейшем «Заказчик», на заключение Договора (Оферты) на
          оказание услуг по просмотру объекта (далее – Договор), являющегося предметом торгов, проводимых в электронной
          форме на электронной площадке в сети Интернет, с учетом положений настоящего Исполнительского договора
          (Оферты).
        </Typography.Text>
        <Typography.Text>
          1.2 В случае принятия изложенных ниже условий физическое лицо, юридическое лицо или индивидуальный
          предприниматель, производящее(-ий) акцепт этой Оферты, становится Заказчиком (в соответствии с пунктом 3
          статьи 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте), а Заказчик и
          Исполнитель совместно — Сторонами Исполнительского договора (далее совместно - «Стороны» и отдельно -
          «Сторона»).
        </Typography.Text>
        <Typography.Text>
          1.3 Акцептом настоящей Оферты признается совершение Заказчиком совокупности следующих действий:
        </Typography.Text>
        <Typography.Text>
          – проставление отметки о согласии и присоединении к условиям Оферты посредством функционала сайта,
          расположенного по адресу в сети Интернет https://kupilot.ru, а также заполнение Заявки;
        </Typography.Text>
        <Typography.Text>
          – оплата вознаграждения в полном объеме в пределах указанного в Договоре срока.
        </Typography.Text>
        <Typography.Text>
          Акцепт Заказчика является безусловным принятием Заказчиком условий настоящей Оферты.
        </Typography.Text>
        <Typography.Text>
          1.4 Исполнитель вправе проводить проверку представленных Заказчиком данных при оформлении Заявки, требовать от
          Заказчика полного и корректного заполнения указанной Заявки или отклонить её без предоставления каких-либо
          пояснений относительно причин отклонения с возвратом Заказчику уплаченных денежных средств. Такой отказ не
          является нарушением Исполнителем условий настоящего Договора и не несёт за собой привлечение Исполнителя к
          какой-либо ответственности. При получении такого отказа Договор считается расторгнутым Исполнителем в
          одностороннем порядке, а Заказчик надлежащим образом, уведомленным о таком расторжении.
        </Typography.Text>
        <Typography.Text>
          1.5 Оферта вступает в силу с момента размещения по адресу в сети Интернет: https://kupilot.ru и действует до
          момента отзыва Оферты Исполнителем.
        </Typography.Text>
        <Typography.Text>
          1.6 Заключая настоящий Договор, Заказчик понимает значение своих действий и способен руководить ими, не
          находится под влиянием заблуждения, обмана, насилия, угрозы. Договор заключен Заказчиком добровольно, с
          предварительным полным ознакомлением с условиями настоящей Оферты, содержание которой Заказчику понятно.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>2 ПРЕДМЕТ ДОГОВОРА</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          2.1 По настоящему Договору Заказчик поручает, а Исполнитель принимает на себя обязательство за плату
          осуществить осмотр объекта недвижимости, являющегося предметом торгов, проводимых в электронной форме на
          электронной площадке в сети Интернет, с учетом положений настоящего Договора (Оферты), указанных в п. 2.2.
          настоящего Договора (далее – Услуги), в объеме Заявки, принятой Исполнителем. Заказчик обязуется принять
          оказанные Исполнителем Услуги.
        </Typography.Text>
        <Typography.Text>
          2.2 По настоящему Договору Исполнитель обязуется в интересах Заказчика совершить следующие действия:
        </Typography.Text>
        <Typography.Text>
          2.2.1 Произвести осмотр предмета Лота, в том числе путем предоставления возможности Заказчику участвовать в
          показе онлайн (в случае предварительного согласования);
        </Typography.Text>
        <Typography.Text>2.2.2 Предоставить Заказчику не менее 20 фото и 1-го видео материалов.</Typography.Text>
        <Typography.Text>
          2.3 Стороны пришли к соглашению, что Заказчик указывает в Заявке, являющейся неотъемлемой частью настоящего
          Договора интернет-ссылку, которая ведет к информации о наименовании электронной площадки, разновидности
          торговой процедуры, идентификационном номере и описании лота.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>3 ПРАВА И ОБЯЗАННОСТИ СТОРОН</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>3.1 Исполнитель обязуется:</Typography.Text>
        <Typography.Text>
          3.1.1 Выполнять предусмотренные пунктом 2.2. настоящего Договора действия надлежащим образом и своевременно,
          руководствуясь указаниями и интересами Заказчика, а также условиями настоящего Договора. Исполнитель
          приступает к исполнению поручения Заказчика по соответствующей Заявке после оплаты Заказчиком цены Договора,
          указанного в п. 4.2. Договора, и поступления денежных средств на счет Исполнителя, указанный в разделе 10
          Договора.
        </Typography.Text>
        <Typography.Text>
          3.1.2 Уведомить Заказчика о дате и времени осмотра предмета Лота, путем направления на электронный
          адрес/сообщение на номер контактного телефона Заказчика, указанного при регистрации на платформе Исполнителя.
        </Typography.Text>
        <Typography.Text>
          3.1.3 Направить Заказчику на электронную почту/в мессенджер Телеграм (Telegram), указанный Заказчиком при
          регистрации на сайте Исполнителя ссылку на облачное хранилище в сети Интернет с не менее 20 фото и 1-го видео
          материалов, в течение 2-х календарных дней с момента проведения просмотра Лота.
        </Typography.Text>
        <Typography.Text>3.2 Исполнитель вправе:</Typography.Text>
        <Typography.Text>
          3.2.1 Отступить от указаний Заказчика, если по обстоятельствам дела это необходимо в интересах Заказчика и
          Исполнитель не получил ответа на свой запрос в течение 2 (Двух) рабочих дней после его получения Заказчиком.
        </Typography.Text>
        <Typography.Text>
          3.2.2 Привлекать к оказанию Услуг по договору третьих лиц, при этом нести ответственность за их действия как
          за свои собственные.
        </Typography.Text>
        <Typography.Text>3.3 Заказчик обязуется:</Typography.Text>
        <Typography.Text>3.3.1 Предоставить Исполнителю корректно заполненную Заявку.</Typography.Text>
        <Typography.Text>
          3.3.2 Заказчик, в случае изъявления желания участия в онлайн показе предмета Лота в течение 48 часов (но не
          позднее 09:00 по Мск дня осмотра), с момента получения от Исполнителя уведомления о дате и времени проведения
          осмотра организатором торгов направляет на электронный адрес Исполнителя services@kupilot.ru сообщение об
          участии в онлайн показе. Онлайн показ осуществляется с использованием мессенджеров Телеграм (Telegram) и/или
          Вотсап (WhatsApp).
        </Typography.Text>
        <Typography.Text>3.3.3 Принять оказанные Исполнителем услуги.</Typography.Text>
        <Typography.Text>3.4 Заказчик вправе:</Typography.Text>
        <Typography.Text>3.4.1 Запрашивать у Исполнителя сведения о ходе исполнения Договора.</Typography.Text>
        <Typography.Text>
          3.4.1 Отказаться от услуги не позднее чем за 24 часа до осмотра предмета Лота, путем направления
          соответствующего сообщения на электронный адрес Исполнителя services@kupilot.ru. В случае отказа от услуги
          менее чем за 24 часа до осмотра предмета Лота, оплата возврату не подлежит.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>4 РАЗМЕР И ПОРЯДОК ЕГО ОПЛАТЫ ПО ДОГОВОРУ</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          4.1 Заказчик производит оплату услуг Исполнителя после оформления Заявки в размере, указанном в п.4.2.
          Договора.
        </Typography.Text>
        <Typography.Text>
          4.2 Стоимость услуг по Договору в отношении одной Заявки зависит от тарифного плана Заказчика и составляет
          следующий размер:
        </Typography.Text>
        <Typography.Text>
          4.2.1 При тарифном плане «Базовый» стоимость услуги составляет 5 000 (Пять тысяч) рублей, НДС не облагается;
        </Typography.Text>
        <Typography.Text>
          4.2.2 При тарифном плане «Стандарт» стоимость услуги составляет 5 000 (Пять тысяч) рублей, НДС не облагается;
        </Typography.Text>
        <Typography.Text>
          4.2.3 При тарифном плане «Максимум» стоимость услуги составляет 5 000 (Пять тысяч) рублей, НДС не облагается;
        </Typography.Text>
        <Typography.Text>
          4.2.4 При тарифном плане «Максимум», оплаченным на год вперёд, первые четыре заявки на Услугу реализуются
          Исполнителем по цене 0 руб.; далее (начиная с пятой заявки) стоимость услуги составляет 5 000 (Пять тысяч)
          рублей, НДС не облагается;
        </Typography.Text>
        <Typography.Text>
          4.2.5 При тарифном плане «Профи» стоимость услуги составляет 5 000 (Пять тысяч) рублей, НДС не облагается;
        </Typography.Text>
        <Typography.Text>
          4.2.6 При тарифном плане «Профи Плюс» стоимость услуги составляет 5 000 (Пять тысяч) рублей, НДС не
          облагается;
        </Typography.Text>
        <Typography.Text>
          4.2.7 При тарифном плане «Профи Макс» стоимость услуги составляет 5 000 (Пять тысяч) рублей, НДС не
          облагается.
        </Typography.Text>
        <Typography.Text>4.3 Исполнитель оказывает Услуги, по соответствующей Заявке.</Typography.Text>
        <Typography.Text>
          4.4 Не позднее 10 (Десяти) рабочих дней после оказания Услуг, Исполнитель направляет Заказчику способами,
          указанными в пп. а)-д) п. 8.3 Договора: Акт об оказанных услугах, подписанный со стороны Исполнителя.
        </Typography.Text>
        <Typography.Text>
          4.5 В течение 5 (Пяти) рабочих дней с даты получения Акта Заказчик подписывает и возвращает Исполнителю один
          экземпляр Акта.
        </Typography.Text>
        <Typography.Text>
          4.6 В случае наличия возражений по Акту Заказчик письменно уведомляет об этом Исполнителя в течение 3 (Трех)
          рабочих дней с даты получения указанных документов.
        </Typography.Text>
        <Typography.Text>
          4.7 В случае если претензии Заказчика окажутся обоснованными, Исполнитель обязан устранить недостатки в
          течение 5 (Пяти) рабочих дней либо заявить о необходимом дополнительном сроке, либо о невозможности устранения
          таких недостатков.
        </Typography.Text>
        <Typography.Text>
          4.8 Подписанные Заказчиком Акт или письменные возражения по данным документам направляются Исполнителю одним
          из способов, указанных в пп. а)-д) п. 8.3 Договора.
        </Typography.Text>
        <Typography.Text>
          4.9 Если в течение срока, указанного в п. 4.5. Договора, Акт не подписан Заказчиком, и Исполнителем не
          получено от Заказчика каких-либо письменных возражений, Услуги считаются принятыми и подписанными Заказчиком
          без замечаний.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>5 ПЕРСОНАЛЬНЫЕ ДАННЫЕ</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>5.1 Исполнитель обязуется: </Typography.Text>
        <Typography.Text>
          5.1.1 Осуществлять обработку, соблюдать конфиденциальность и обеспечить безопасность персональных данных в
          соответствии с Федеральным законом от «27» июля 2006 года № 152-ФЗ «О персональных данных».
        </Typography.Text>
        <Typography.Text>
          5.1.2 При обработке персональных данных принимать необходимые правовые, организационные и технические меры или
          обеспечивать их принятие для защиты персональных данных от неправомерного или случайного доступа к ним,
          уничтожения, изменения, блокирования, копирования, предоставления, распространения, а также от иных
          неправомерных действий в отношении персональных данных.
        </Typography.Text>
        <Typography.Text>
          5.1.3 Не осуществлять передачу полученных персональных данных третьим лицам без предварительного письменного
          разрешения Заказчика и согласия субъекта персональных данных.
        </Typography.Text>
        <Typography.Text>
          5.3 Исполнитель гарантирует ограничение обработки персональных данных достижением целей, определенных в Оферте
          и формах согласий, размещённых на сайте, и недопущение обработки персональных данных, несовместимой с целями
          сбора персональных данных.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>6 ОТВЕТСТВЕННОСТЬ СТОРОН</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          6.1 Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств в
          соответствии с Договором и законодательством Российской Федерации.
        </Typography.Text>
        <Typography.Text>
          6.2 Каждая из Сторон должна исполнять свои обязательства надлежащим образом, оказывая всевозможное содействие
          другой Стороне.
        </Typography.Text>
        <Typography.Text>
          6.3 Сторона, нарушившая свои обязательства по Договору, должна без промедления устранить эти нарушения.
        </Typography.Text>
        <Typography.Text>
          6.4 Исполнитель не несет ответственность за достоверность и актуальность сведений, представленных
          организатором торгов на электронной площадке.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>7 СРОК ДЕЙСТВИЯ ДОГОВОРА И ДОСРОЧНОЕ РАСТОРЖЕНИЕ ДОГОВОРА</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          7.1 Договор вступает в силу с даты его подписания Сторонами и действует до полного выполнения Сторонами своих
          обязательств по Договору.
        </Typography.Text>
        <Typography.Text>
          7.2 Договор подлежит досрочному расторжению в случае снятия Лота с продажи до осуществления осмотра объекта
          недвижимости.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>8 ПРОЧИЕ ПОЛОЖЕНИЯ</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          8.1 Стороны установили, что все юридически значимые документы должны быть оформлены письменно и направляться
          по адресам Сторон, в соответствии с указанными в Договоре способами.
        </Typography.Text>
        <Typography.Text>
          8.2 Стороны вправе использовать при заключении Договора, оформлении Приложений, дополнительных соглашений,
          Актов, Отчетов, счетов на оплату, а также подписании и направлении иных документов по Договору простую
          электронную подпись (ПЭП), за исключением случаев, когда законом прямо установлено подписание конкретных
          документов усиленной неквалифицированной электронной подписью (УНЭП) и/или усиленной квалифицированной
          электронной подписью (УКЭП).
        </Typography.Text>
        <Typography.Text>8.3 Допустимые способы направления юридически значимых документов:</Typography.Text>
        <Typography.Text>а) отправка собственным курьером под расписку;</Typography.Text>
        <Typography.Text>б) через курьерскую службу с описью вложения; </Typography.Text>
        <Typography.Text>в) по почте с описью вложения;</Typography.Text>
        <Typography.Text>
          г) через систему электронного документооборота в виде электронных документов, подписанных усиленными
          квалифицированными подписями Сторон;
        </Typography.Text>
        <Typography.Text>д) по адресу электронной почты.</Typography.Text>
        <Typography.Text>
          8.4 Стороны установили, что электронный документ (электронный образ документа), направленный с помощью систем
          электронного документооборота или с адресов электронной почты, указанных в разделе 10 Договора, имеют
          юридическую силу документов на бумажных носителях, заверенных собственноручной подписью Стороны, оформленных в
          соответствии с требованиями законодательства Российской Федерации, и порождают аналогичные документам на
          бумажных носителях права и обязанности Сторон. Данные документы в электронной форме могут служить
          доказательством в суде.
        </Typography.Text>
        <Typography.Text>
          8.5 При использовании Сторонами электронной почты, указанной в разделе 10 Договора, направляемый с ее помощью
          электронный документ (электронный образ документа) считается подписанным ПЭП отправителя (уполномоченным
          представителем Стороны), созданной с использованием адреса его электронной почты. Получатель электронного
          документа (электронного образа документа) определяет лицо, подписавшее такой документ, по используемому им
          адресу электронной почты.
        </Typography.Text>
        <Typography.Text>
          8.6 Стороны обязуются соблюдать конфиденциальность ключа ПЭП. В частности, Стороны не имеют права
          предоставлять доступ к своей электронной почте третьим лицам, и несут полную ответственность за их сохранность
          и индивидуальное использование, самостоятельно выбирая способ их хранения и ограничения к ним доступа. В
          случае утраты или несанкционированного доступа к электронной почте Сторона обязана незамедлительно сообщить о
          данном факте другой Стороне способом, позволяющим установить, что сообщение исходит от Стороны.
        </Typography.Text>
        <Typography.Text>
          8.7 Стороны пришли к соглашению, что подписание и обмен электронными документами (электронными образами
          документов) допускается с использованием электронного документооборота. Все юридически значимые документы
          Стороны могут направлять по телекоммуникационным каналам связи в рамках электронного документооборота между
          Сторонами, организованного оператором электронного документооборота с использованием системы электронного
          документооборота.
        </Typography.Text>
        <Typography.Text>
          8.8 Электронный документооборот между Сторонами может осуществляться с использованием усиленной
          неквалифицированной электронной подписи (УНЭП) и/или усиленной квалифицированной электронной подписи (УКЭП)
          уполномоченного лица каждой Стороны, выданной любым аккредитованным удостоверяющим центром, осуществляющим
          свою деятельность в соответствии с требованиями действующего законодательства Российской Федерации
          (Федеральный закон «Об электронной подписи» от 06.04.2011 № 63-ФЗ).
        </Typography.Text>
        <Typography.Text>
          8.9 Стороны обязаны незамедлительно информировать друг друга о невозможности обмена документами в электронном
          виде, подписанными ЭЦП, в случае технического сбоя внутренних систем Сторон. В этом случае в период действия
          такого сбоя Стороны производят обмен документами на бумажном носителе, подписанными в соответствии с
          требованиями действующего законодательства.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>9 ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          9.1 Во всем остальном, что не предусмотрено настоящим Договором, Стороны руководствуются действующим
          законодательством Российской Федерации.
        </Typography.Text>
        <Typography.Text>
          9.2 Стороны обязуются отвечать на письменные запросы (уведомления, извещения, требования и прочее) другой
          Стороны в течение 10 (Десяти) рабочих дней с момента получения такого запроса.
        </Typography.Text>
        <Typography.Text>
          9.3 Стороны в трехдневный срок до вступления соответствующих изменений в силу информируют друг друга обо всех
          изменениях своих адресов, платежных реквизитов и других данных, которые могут повлиять на исполнение Договора.
          На Сторону, нарушившую данную обязанность, возлагаются все неблагоприятные последствия и риски отсутствия у
          другой Стороны актуальной информации об адресах, в частности, все юридически значимые сообщения считаются
          доставленными, а их юридические последствия – возникшими, при условии доставки по предыдущему доведенному до
          отправителя адресу получателя.
          <br /> <br />
          Все действия, совершенные Сторонами по адресам и банковским реквизитам, указанным в Договоре, до поступления
          уведомлений об их изменении, считаются совершенными надлежащим образом и засчитываются в счет исполнения
          обязательств по Договору.
        </Typography.Text>
        <Typography.Text>
          9.4 Приложения к настоящему Договору, являющиеся его неотъемлемой частью: Приложение № 1 – Форма «Акт
          оказанных услуг».
        </Typography.Text>
      </div>

      <Typography.Title level={3}>10 РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Исполнитель: ООО «КУПИЛОТ»
          <br />
          Юридический адрес: 125504, г. Москва, ш. Дмитровское, д. 71Б, помещ. 16/4
          <br />
          ИНН: 9731132898 КПП: 774301001
          <br />
          Расчетный счет: 40702810901100035778
          <br />
          В банке: АО "АЛЬФА-БАНК"
          <br />
          БИК: 044525593
          <br />
          Корреспондентский счет: <br />
          30101810200000000593
          <br /> <br />
          Адрес эл. почты: services@kupilot.ru
          <br />
          Номер телефона: +7 (495) 773-33-73
          <br />
        </Typography.Text>
      </div>
    </PageContainer>
  );
});

export default OnlinePublicOfferPage;

import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ILotBase, ILotPublic, Status } from 'entities/lot';
import useToggleState from 'hooks/useToggleState';
import { LotModel } from 'models/LotModel';

import { ImageGallery } from '../ImageGallery';
import Layout from '../Layout';
import { Meta } from '../Meta';

import { Footer } from './Footer';
import { Header } from './Header';
import { Main } from './Main';

import s from './LotCard.module.scss';
import { useInView } from 'react-intersection-observer';
import { CarouselImageSize } from 'components/ImgCarousel';
import { useWidth } from 'hooks/useWidth';
import { useModalFilterText, useModalText } from 'components/ModalText/ModalText.context';
import { ValueModel } from 'models/ValueModel';
import { useUserGetTariffInfo } from 'hooks/usePopupFilterAuth';

export type LotCardSmallProps<T> = {
  item: LotCardSmallItemData<T>;
  additionalTags?: Status[];
  style?: React.CSSProperties;
  className?: string;
  onTouchStartImageGallery?: () => void;
  onTouchEndImageGallery?: () => void;
  favoritePopup?: boolean;
  similar?: boolean;
};

export type LotCardSmallItemData<T> = T &
  ILotBase & {
    startToggleFavorite?: () => void;
    startToggleSubscription?: () => void;
    priceMinFormatted?: string | null;
    totalAreaFormatted?: string | null;
    isInFavorite?: ValueModel<boolean>;
    isInSubscribed?: ValueModel<boolean>;
    lotCardAddress?: string | null;
  };

export const LotCardSmallIntersection = observer(
  <T,>(props: LotCardSmallProps<T> & { onView?: (id: number | number[]) => void }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
    });
    const refStop = React.useRef(false);

    React.useEffect(() => {
      if (inView && !refStop.current && props.onView) {
        refStop.current = true;
        props.onView(props.item.id);
      }
    }, [inView]);

    return (
      <div ref={ref}>
        <LotCardSmall<T> {...props} />
      </div>
    );
  },
);

const LotCardSmall = <T,>({
  item,
  className,
  style,
  additionalTags,
  onTouchEndImageGallery,
  onTouchStartImageGallery,
  favoritePopup,
  similar = false,
}: LotCardSmallProps<T>): React.ReactElement<T> => {
  const { open, close } = useToggleState();
  const { changeMsg, toggleOpen } = useModalText();
  const { toggleOpen: toggleOpenTariff, changeMsg: changeMsgTariff } = useModalFilterText();
  const tariffUser = useUserGetTariffInfo();

  const handleFavoriteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        item.startToggleFavorite &&
        tariffUser.authorized &&
        tariffUser.tariffInfo &&
        tariffUser.tariffInfo.lot_favorite
      ) {
        item.startToggleFavorite();
      } else {
        if ((tariffUser.tariffInfo == null || !tariffUser.tariffInfo.lot_favorite) && tariffUser.authorized) {
          changeMsgTariff('Для добавления лота в избранное оформите тариф');
          toggleOpenTariff(true);
        } else {
          changeMsg('Для доступа ко всем возможностям зарегистрируйтесь или войдите в личный кабинет');
          toggleOpen(true);
        }
      }
    },
    [item],
  );

  const handleSubscribeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (
        item.startToggleSubscription &&
        tariffUser.authorized &&
        tariffUser.tariffInfo &&
        tariffUser.tariffInfo.lot_notification
      ) {
        item.startToggleSubscription();
      } else {
        console.log(tariffUser);
        if ((tariffUser.tariffInfo == null || !tariffUser.tariffInfo.lot_notification) && tariffUser.authorized) {
          changeMsgTariff('Для подписки на изменения по лоту оформите тариф');
          toggleOpenTariff(true);
        } else {
          changeMsg('Для доступа ко всем возможностям зарегистрируйтесь или войдите в личный кабинет');
          toggleOpen(true);
        }
      }
    },
    [item],
  );

  const { width = window.innerWidth } = useWidth();
  const images = item.images ? item.images.slice(0, 4) : [];
  const sizeImg = React.useMemo(() => {
    if (similar) {
      if (width < 768) {
        return CarouselImageSize.sm;
      }
      return CarouselImageSize.similar;
    }

    return undefined;
  }, [similar]);

  return (
    <Layout
      item={item as unknown as LotModel}
      style={style}
      className={`${className} ${s['lot-card']}`}
      handleMouseEnter={open}
      handleMouseLeave={close}
      column
    >
      <div
        onTouchEnd={onTouchEndImageGallery}
        onTouchStart={onTouchStartImageGallery}
        onTouchMove={onTouchStartImageGallery}
      >
        <ImageGallery images={images} className={s.image + ' lot-card-image'} imgSize={sizeImg} showBullets />
      </div>
      <div className={s['lot-card__main-content']}>
        <Header
          status={item.status}
          auctionFormat={item.auctionFormat}
          auctionType={item.auctionType}
          objectType={item.objectType}
          priceMin={item?.priceMinFormatted || null}
          totalArea={item?.totalAreaFormatted || null}
          isFavorite={item?.isInFavorite?.value || false}
          handleFavoriteClick={handleFavoriteClick}
          isSubscribed={item?.isInSubscribed?.value || false}
          handleSubscribeClick={handleSubscribeClick}
          additionalTags={additionalTags}
          isDowngradeAuction={item.isDowngradeAuction}
          favoritePopup={favoritePopup}
          similar={similar}
        />

        <Main address={item.lotCardAddress} source={item.source} column />

        {!similar && (
          <Meta floor={item.floor} totalFloors={item.amountOfFloors} entranceCount={item.entrance} similar={similar} />
        )}
        <Footer dateAdded={item.dateAdded} viewsCount={item.viewsCount} />
      </div>
    </Layout>
  );
};

export default observer(LotCardSmall);

import { Button, Input, notification, Popconfirm } from 'antd';
import RobotIcon from 'components/icons/Robot';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import s from './RobotAction.module.scss';
import { useRootStore } from 'stores/RootStore';
import { filtersEmptyObject } from 'utils/function';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { RobotCreateFilterModel } from 'entities/robot';

const TITLE = 'Сохранить умный поиск';

export const RobotAction = observer(
  ({
    filters,
    title = TITLE,
    iconHide = false,
    skipInitList = false,
  }: {
    filters: RobotCreateFilterModel;
    title?: string;
    iconHide?: boolean;
    skipInitList?: boolean;
  }) => {
    const [open, setOpen] = useState(false);
    const { robotStore, userStore } = useRootStore();
    const { isLoadingRobot, robotName, robotFilters, putRobotItemFetch } = robotStore;
    const [api, contextHolder] = notification.useNotification();

    const activeFilters = useMemo(() => {
      const data = { ...filters };
      const items = filtersEmptyObject(data);
      return { items, count: Object.keys(items).length };
    }, [filters]);

    const disabled = useMemo(() => {
      return isLoadingRobot || robotName.value.length === 0 || activeFilters.count === 0;
    }, [isLoadingRobot, robotName.value, activeFilters.count]);

    const notificationWindow = (type: 'success' | 'update' | 'error' = 'error') => {
      const msg = {
        success: 'Фильтр успешно создан',
        update: 'Готово!',
        error: 'Войдите в профиль для сохранения фильтра',
      };
      api.open({
        message: msg[type],
        duration: 4.5,
        icon:
          type === 'error' ? (
            <WarningOutlined style={{ color: 'red' }} />
          ) : (
            <CheckOutlined style={{ color: 'green' }} />
          ),
      });
    };

    const searchFilter = useMemo(() => {
      const index = robotFilters.items.findIndex((item) => item.name === robotName.value);
      return {
        index: index,
        item: index != -1 ? robotFilters.items[index] : null,
      };
    }, [robotFilters.items, robotName.value]);

    const onSave = () => {
      if (searchFilter.index !== -1) {
        setOpen(true);
      } else {
        if (userStore.authorized) {
          robotStore.postRobotItemFetch(activeFilters.items).then(() => {
            notificationWindow('success');
            robotName.reset();
          });
        } else {
          notificationWindow('error');
        }
      }
    };

    const onUpdate = () => {
      if (searchFilter.item) {
        putRobotItemFetch(searchFilter.item.id, activeFilters.items, robotName.value).then(() => {
          notificationWindow('update');
          robotName.reset();
          setOpen(false);
        });
      }
    };

    const onCancel = () => setOpen(false);

    return (
      <div className={s.filterActions}>
        {contextHolder}
        <div className={s.robotWrap}>
          <div className={s.robotLabel}>
            {!iconHide && <RobotIcon />}
            <div>{title}</div>
          </div>
          <div className={s.wrap__input}>
            <Input
              placeholder="Введите название"
              className={s.input}
              onChange={(e) => robotName.change(e.target.value)}
              value={robotName.value}
              status={robotName.isError ? 'error' : undefined}
            />
            <Popconfirm
              open={open}
              placement="top"
              title={'Заменить его?'}
              okText="Да"
              icon={<div></div>}
              cancelText="Нет"
              onConfirm={onUpdate}
              onCancel={onCancel}
              rootClassName={s.popup}
            >
              <Button loading={isLoadingRobot} disabled={disabled} className={s.btn} type="primary" onClick={onSave}>
                Сохранить
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  },
);

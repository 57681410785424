import { makeObservable, action, computed } from 'mobx';
import { CallBackProps, ACTIONS, EVENTS } from 'react-joyride';

import { ElementId } from 'config/ids';
import { routerUrls } from 'config/routes';
import { localStorage, LocalStorageKey } from 'models/LocalStorage';
import { ValueModel } from 'models/ValueModel';
import { IRouterStore } from 'stores/RootStore/RouterStore';

import { IOnboardingStore, CustomStep } from './types';

type OnboardingStoreParams = {
  routerStore: IRouterStore;
};

export class OnboardingStore implements IOnboardingStore {
  isOnboardingPassedModel: ValueModel<boolean>;
  // Прошёл ли пользователь первую часть онбординга (на странице списка лотов)
  isInitialOnboardingPassedModel: ValueModel<boolean>;
  isOnboardingStartedModel: ValueModel<boolean> = new ValueModel(false);
  stepIndex: ValueModel<number> = new ValueModel(0);
  lotPageUrl: ValueModel<string | null> = new ValueModel<string | null>(null);
  private routerStore: IRouterStore;
  private runModel: ValueModel<boolean> = new ValueModel(false);

  constructor({ routerStore }: OnboardingStoreParams) {
    makeObservable<this, 'pauseOnboarding'>(this, {
      isOnboardingRunning: computed,

      handleOnboardingStep: action.bound,
      finishOnboarding: action.bound,
      startOver: action.bound,
      pauseOnboarding: action.bound,
      resumeOnboardingOnLotPage: action.bound,
      resumeOnboardingOnLotsListPage: action.bound,
      startOnboarding: action.bound,
    });

    this.routerStore = routerStore;

    // TODO: #45360335 off onboarding
    this.isOnboardingPassedModel = new ValueModel(
      //localStorage.getItem(LocalStorageKey.isOnboardingPassed) === 'true'
      true,
    );
    this.isInitialOnboardingPassedModel = new ValueModel(this.isOnboardingPassedModel.value);
  }

  get stepTexts(): string[] {
    return [
      'Здесь вы можете войти в свой аккаунт для доступа к личным настройкам и истории покупок',
      'Используйте фильтры для более точного подбора лотов, соответствующих вашим предпочтениям',
      'Эти фильтры помогут вам быстро сортировать лоты по актуальности, цене, новизне и другим критериям',
      'Здесь вы видите все доступные предложения. Нажмите на лот для полного ознакомления',
      'Добавляйте в избранное лоты, которые вам понравились, чтобы легко к ним вернуться в любое время',
      'Здесь вы будете получать уведомления об обновлениях статуса ваших лотов и других важных событиях',
      'В этом разделе представлена подробная информация о лоте, включая цену, описание и ключевые характеристики',
      'Здесь расположены дополнительные визуальные и текстовые материалы о лоте – фотографии, видео и документы',
      'Найдите здесь всю необходимую техническую документацию и информацию о коммунальных услугах, важных для полного понимания условий сделки',
    ];
  }

  get desktopSteps(): CustomStep[] {
    return [
      {
        target: `#${ElementId.loginButton}`,
        scrollTarget: `#${ElementId.loginButton}`,
        content: this.stepTexts[0],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.filters}`,
        scrollTarget: `#${ElementId.filters}`,
        content: this.stepTexts[1],
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: `#${ElementId.sortControlsDesktop}`,
        scrollTarget: `#${ElementId.sortControlsDesktop}`,
        content: this.stepTexts[2],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.lotsList}`,
        scrollTarget: `#${ElementId.root}`,
        content: this.stepTexts[3],
        disableBeacon: true,
        placement: 'top',
      },
      {
        target: `#${ElementId.lotCardFavoriteButtonDesktop}`,
        scrollTarget: null,
        content: this.stepTexts[4],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.lotCardSubscribeButtonDesktop}`,
        scrollTarget: null,
        content: this.stepTexts[5],
        disableBeacon: true,
      },
      // Переход на страницу лота
      {
        target: `#${ElementId.lotPageMainInfo}`,
        scrollTarget: `#${ElementId.lotPageMainInfo}`,
        content: this.stepTexts[6],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.lotPageImageCarouselDesktop}`,
        scrollTarget: `#${ElementId.lotPageImageCarouselDesktop}`,
        content: this.stepTexts[7],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.lotPagePropertyCharacteristicsDesktop}`,
        scrollTarget: `#${ElementId.lotPagePropertyCharacteristicsDesktop}`,
        content: this.stepTexts[8],
        disableBeacon: true,
      },
    ];
  }

  get mobileSteps(): CustomStep[] {
    return [
      {
        target: `#${ElementId.loginButton}`,
        scrollTarget: `#${ElementId.loginButton}`,
        content: this.stepTexts[0],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.filtersButton}`,
        scrollTarget: `#${ElementId.filtersButton}`,
        content: this.stepTexts[1],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.sortControlsMobile}`,
        scrollTarget: `#${ElementId.sortControlsMobile}`,
        content: this.stepTexts[2],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.lotsList}`,
        scrollTarget: `#${ElementId.root}`,
        content: this.stepTexts[3],
        disableBeacon: true,
        placement: 'top',
      },
      {
        target: `#${ElementId.lotCardFavoriteButtonMobile}`,
        scrollTarget: `#${ElementId.lotCardFavoriteButtonMobile}`,
        content: this.stepTexts[4],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.lotCardSubscribeButtonMobile}`,
        scrollTarget: `#${ElementId.lotCardSubscribeButtonMobile}`,
        content: this.stepTexts[5],
        disableBeacon: true,
      },
      // Переход на страницу лота
      {
        target: `#${ElementId.lotPageMainInfo}`,
        scrollTarget: `#${ElementId.root}`,
        content: this.stepTexts[6],
        disableBeacon: true,
        placement: 'top',
      },
      {
        target: `#${ElementId.lotPageImageCarouselMobile}`,
        scrollTarget: `#${ElementId.lotPageImageCarouselMobile}`,
        content: this.stepTexts[7],
        disableBeacon: true,
      },
      {
        target: `#${ElementId.lotPagePropertyCharacteristicsMobile}`,
        scrollTarget: `#${ElementId.lotPagePropertyCharacteristicsMobile}`,
        content: this.stepTexts[8],
        disableBeacon: true,
      },
    ];
  }

  // Запущен ли онбординг в данный момент
  get isOnboardingRunning(): boolean {
    return this.runModel.value;
  }

  handleOnboardingStep(data: CallBackProps): void {
    if (this.isOnboardingPassedModel.value) {
      return;
    }

    const { action, index, type, step } = data;

    if (type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
      this.finishOnboarding();

      return;
    }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Продолжение онбординга на странице лота
      if (
        (step.target === `#${ElementId.lotCardSubscribeButtonDesktop}` ||
          step.target === `#${ElementId.lotCardSubscribeButtonMobile}`) &&
        action === ACTIONS.NEXT
      ) {
        this.pauseOnboarding();
        this.isInitialOnboardingPassedModel.change(true);

        if (this.lotPageUrl.value) {
          this.routerStore.navigate(this.lotPageUrl.value);
        }

        return;
      }

      // Переход назад на страницу списка лотов
      if (step.target === `#${ElementId.lotPageMainInfo}` && action === ACTIONS.PREV) {
        this.pauseOnboarding();

        this.routerStore.navigate(routerUrls.lotsList.create());

        return;
      }

      this.stepIndex.change(index + (action === ACTIONS.PREV ? -1 : 1));
    }
  }

  startOnboarding(): void {
    this.runModel.change(true);
    this.isOnboardingStartedModel.change(true);
  }

  startOver(): void {
    this.stepIndex.change(0);
  }

  finishOnboarding() {
    this.isOnboardingPassedModel.change(true);
    this.runModel.change(false);
    localStorage.setItem(LocalStorageKey.isOnboardingPassed, 'true');
  }

  resumeOnboardingOnLotsListPage(): void {
    setTimeout(() => {
      this.stepIndex.change(5);
      this.runModel.change(true);
    }, 500);
  }

  resumeOnboardingOnLotPage(): void {
    setTimeout(() => {
      this.stepIndex.change(6);
      this.runModel.change(true);
    }, 500);
  }

  setLotPageUrl(url: string) {
    this.lotPageUrl.change(url);
  }

  private pauseOnboarding(): void {
    this.runModel.change(false);
  }
}

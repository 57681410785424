import * as React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { App } from 'pages/App';
import { FavoritesPage } from 'pages/FavoritesPage';
import { LotPage } from 'pages/LotPage';
import { LotsListPage } from 'pages/LotsListPage';
import { Profile } from 'pages/Profile';
import { ProfileHistory } from 'pages/ProfileHistory';
import ProfileNotificationPage from 'pages/ProfileNotificationPage/ProfileNotificationPage';
import { ProfileRobotPage } from 'pages/ProfileRobotPage';
import { ProfileSettingsPage } from 'pages/ProfileSettingsPage';
import { SubscriptionsPage } from 'pages/SubscriptionsPage';
import { TariffsPage } from 'pages/TariffsPage';
import { TariffsPremiumPage } from 'pages/TariffsPremiumPage';
import CommonError from 'pages/errors/CommonError';
import { ProfileServicesPage } from 'pages/ProfileServicesPage';

import { routerUrls } from './routerUrls';
import {
  AuditLegalPublicOfferPage,
  OnlinePublicOfferPage,
  ServisesInfoEscortPage,
  ServisesInfoOnlinePage,
  ServisesInfoOrderPage,
} from 'pages/ServicesInfoPage';
import { AgreePage, CookiesPage, PolicyPage, TermsOfService } from 'pages/StaticPages';
import AgreementPage from 'pages/StaticPages/AgreementPage/AgreementPage';
import { HomePage } from 'pages/Home';
import { YandexMapPage } from 'pages/YandexMap';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <CommonError />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: routerUrls.lotsList.mask,
        element: <LotsListPage />,
      },
      {
        path: routerUrls.lotDetail.mask,
        element: <LotPage />,
      },
      {
        path: routerUrls.profile.mask,
        element: <Profile />,
      },
      {
        path: routerUrls.favorites.mask,
        element: <FavoritesPage />,
      },
      {
        path: routerUrls.history.mask,
        element: <ProfileHistory />,
      },
      {
        path: routerUrls.tariffs.mask,
        element: <TariffsPage />,
      },
      {
        path: routerUrls.tariffsPremium.mask,
        element: <TariffsPremiumPage />,
      },
      {
        path: routerUrls.profileRobot.mask,
        element: <ProfileRobotPage />,
      },
      {
        path: routerUrls.subscriptions.mask,
        element: <SubscriptionsPage />,
      },
      {
        path: routerUrls.profileNotification.mask,
        element: <ProfileNotificationPage />,
      },
      {
        path: routerUrls.profileSettings.mask,
        element: <ProfileSettingsPage />,
      },
      {
        path: routerUrls.profileServices.mask,
        element: <ProfileServicesPage />,
      },
      {
        path: routerUrls.servicesOnline.mask,
        element: <ServisesInfoOnlinePage />,
      },
      {
        path: routerUrls.servicesEscort.mask,
        element: <ServisesInfoEscortPage />,
      },
      {
        path: routerUrls.servicesOrder.mask,
        element: <ServisesInfoOrderPage />,
      },
      {
        path: routerUrls.staticPolicy.mask,
        element: <PolicyPage />,
      },
      {
        path: routerUrls.staticAgree.mask,
        element: <AgreePage />,
      },
      {
        path: routerUrls.staticAgreement.mask,
        element: <AgreementPage />,
      },
      {
        path: routerUrls.staticTermsOfService.mask,
        element: <TermsOfService />,
      },
      {
        path: routerUrls.staticCookies.mask,
        element: <CookiesPage />,
      },
      {
        path: routerUrls.yandexMap.mask,
        element: <YandexMapPage />,
      },
      {
        path: routerUrls.staticOnlinePublicOffer.mask,
        element: <OnlinePublicOfferPage />,
      },
      {
        path: routerUrls.staticAuditLegalPublicOffer.mask,
        element: <AuditLegalPublicOfferPage />,
      },
      {
        path: '*',
        element: <Navigate to={routerUrls.lotsList.create()} replace />,
      },
    ],
  },
]);

export default router;

import { Input, InputRef } from 'antd';
import cn from 'classnames';
import { useWidth } from 'hooks/useWidth';
import React from 'react';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import s from './SearchBar.module.scss';
import { PriceSelect } from '../ScreenFilters/Filters/components/PriceSelect';
import { Geography } from '../ScreenFilters/Filters/components/Geography';
import { MobileSearch } from '../ScreenFilters/MobileSearch/MobileSearch';
import { observer } from 'mobx-react-lite';

const SearchBar = () => {
  const { filters } = useLotListStore();
  const { isDesktop } = useWidth();
  const refInput = React.useRef<InputRef | null>(null);

  const handleSearchInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filters.search.change(e.target.value);
    },
    [filters.search.change],
  );

  const onSearch = () => {
    filters.setSearchValue(filters.search.value);
    filters.setSideFilters();
  };

  React.useEffect(() => {
    refInput.current?.input?.classList.add('ym-record-keys');
  }, []);
  //TODO: сделать в отдельной компоненте десктоп
  return isDesktop ? (
    <div className={s.wrap}>
      <Input
        ref={refInput}
        className={cn(s['search-bar'])}
        value={filters.search.value}
        onChange={handleSearchInput}
        placeholder="Тип объектов, кадастровый номер"
        size="large"
      />

      <PriceSelect className={s.select} />
      <Geography
        placeholderSelect="Регион"
        className={s.select + ' ' + s.border__right + ' ' + s.w207}
        dropdownStyle={{ width: 'min-content' }}
      />

      <button type="button" onClick={onSearch} className={s.btn}>
        Найти
      </button>
    </div>
  ) : (
    <div>
      <MobileSearch />
    </div>
  );
};

export default observer(SearchBar);

import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStore } from 'hooks/useLocalStore';
import { useRootStore } from 'stores/RootStore';

import { LotListProfileHistoryStore } from './LotListProfileHistoryStore';

type LotListProfileHistoryStoreContextValue = LotListProfileHistoryStore;

const LotListProfileHistoryStoreContext = React.createContext<LotListProfileHistoryStoreContextValue | null>(null);

export const LotListProfileHistoryStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [queryParams] = useSearchParams();
  const rootStore = useRootStore();

  const store = useLocalStore(() => new LotListProfileHistoryStore({ queryParams, rootStore }));

  React.useEffect(() => {
    store.fetchHistoryLotItems({ replace: true });
  }, []);

  return (
    <LotListProfileHistoryStoreContext.Provider value={store}>{children}</LotListProfileHistoryStoreContext.Provider>
  );
};

export const useLotListProfileHistoryStore = () => {
  const context = React.useContext(LotListProfileHistoryStoreContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};

import * as React from 'react';

import { BaseIconProps } from '../commonProps';

const CloseCircledIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_99_197)">
        <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
        <path
          d="M14.8172 6.0668L10.8849 10.0002L14.8172 13.9335C15.0609 14.1773 15.0609 14.5737 14.8172 14.8175C14.6947 14.94 14.5347 15 14.3747 15C14.2147 15 14.0547 14.9387 13.9323 14.8175L10 10.8841L6.06775 14.8175C5.94525 14.94 5.78526 15 5.62527 15C5.46528 15 5.30529 14.9387 5.1828 14.8175C4.93907 14.5737 4.93907 14.1773 5.1828 13.9335L9.11505 10.0002L5.1828 6.0668C4.93907 5.82299 4.93907 5.42666 5.1828 5.18285C5.42654 4.93905 5.82276 4.93905 6.0665 5.18285L9.99875 9.11621L13.931 5.18285C14.1747 4.93905 14.571 4.93905 14.8147 5.18285C15.0584 5.42666 15.0584 5.82299 14.8147 6.0668H14.8172Z"
          fill="#252525"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_197">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseCircledIcon;

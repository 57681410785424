import { Form, Input as AntInput, Typography } from 'antd';
import cn from 'classnames';
import { E164Number } from 'libphonenumber-js/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Input, { parsePhoneNumber } from 'react-phone-number-input/input';

import { ValueModel } from 'models/ValueModel';
import { useRootStore } from 'stores/RootStore';

import 'react-phone-number-input/style.css';

import s from './RegisterForm.module.scss';

const RegisterForm = () => {
  const { authStore } = useRootStore();
  const { authType, phone, email, firstName, lastName, patronymic, isAuthStoreKey, isValueModel } = authStore;

  const handlePhoneChange = React.useCallback(
    (value?: E164Number | undefined) => {
      if (value) {
        phone.change(parsePhoneNumber(value)?.number || null);
      }
    },
    [phone],
  );

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isAuthStoreKey(e.target.id) && isValueModel(authStore[e.target.id])) {
        const valueModel = authStore[e.target.id] as ValueModel<string>;
        valueModel.change(e.target.value);
      }
    },
    [email, firstName, lastName, patronymic],
  );

  return (
    <Form className={s.form} layout="vertical" autoComplete="off">
      <Form.Item
        name={authType.value === 'email' ? 'email' : 'phone'}
        label={authType.value === 'email' ? 'Ваша почта' : 'Ваш телефон'}
        colon
        rules={[{ required: true }]}
      >
        {authType.value === 'email' ? (
          <>
            <AntInput
              className={s.form__input + ' ym-record-keys'}
              status={email.isError ? 'error' : undefined}
              value={email.value || undefined}
              onChange={handleInputChange}
              size="large"
              id="email"
              placeholder="Email"
            />
            <Typography.Text type="danger" className={s.form__input__error}>
              {email.error}
            </Typography.Text>
          </>
        ) : (
          <>
            <div className={s.form__phone__wrap}>
              <Input
                className={cn(s.form__phone, phone.error && s.form__phone_error, ' ym-record-keys')}
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                onChange={handlePhoneChange}
                value={phone.value || undefined}
                country="RU"
                size="large"
              />
            </div>
            <Typography.Text type="danger" className={s.form__input__error}>
              {phone.error}
            </Typography.Text>
            <Typography.Text className={s.form__text__info}>
              Если у вас нет российского номера, зарегистрируйтесь{' '}
              <span role="button" onClick={() => authType.change('email')}>
                по почте
              </span>
            </Typography.Text>
          </>
        )}
      </Form.Item>
      <Form.Item name="firstName" label="Имя" colon rules={[{ required: true }]}>
        <AntInput
          className={s.form__input + ' ym-record-keys'}
          status={firstName.isError ? 'error' : undefined}
          value={firstName.value || undefined}
          onChange={handleInputChange}
          size="large"
          placeholder="Введите имя"
          id={'firstName'}
        />
        <Typography.Text type="danger" className={s.form__input__error}>
          {firstName.error}
        </Typography.Text>
      </Form.Item>
      <Form.Item name="lastName" label="Фамилия" colon rules={[{ required: true }]}>
        <AntInput
          className={s.form__input + ' ym-record-keys'}
          status={lastName.isError ? 'error' : undefined}
          value={lastName.value || undefined}
          onChange={handleInputChange}
          size="large"
          placeholder="Введите фамилию"
          id="lastName"
        />
        <Typography.Text type="danger" className={s.form__input__error}>
          {lastName.error}
        </Typography.Text>
      </Form.Item>
      <Form.Item name="patronymic" colon label="Отчество">
        <AntInput
          className={s.form__input + ' ym-record-keys'}
          status={patronymic.isError ? 'error' : undefined}
          value={patronymic.value || undefined}
          onChange={handleInputChange}
          size="large"
          placeholder="Введите отчество"
          id="patronymic"
        />
        <Typography.Text type="danger" className={s.form__input__error}>
          {patronymic.error}
        </Typography.Text>
      </Form.Item>
    </Form>
  );
};

export default observer(RegisterForm);

import { SearchOutlined } from '@ant-design/icons';
import { Input, Select, Spin, Typography } from 'antd';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { FilterOption } from 'entities/lotFilters';
import { MultiSelectItem } from './MultiSelectItem';

import s from './Select.module.scss';
import { observer } from 'mobx-react-lite';

type SelectProps = {
  options: FilterOption[];
  value?: FilterOption['id'] | null;
  onChange: (id: FilterOption['id'] | null) => void;
  isOptionsLoading: boolean;
  withSearch?: boolean;
  onSearch?: (value: string) => void;
  searchValue?: string;
  className?: string;
  placeholder?: string;
  hideSelectedList?: boolean;
  withActions?: boolean;
  dropdownStyle?: React.CSSProperties;
  onLoadMore?: () => void;
};

const SelectCustom = observer(
  ({
    options,
    value,
    onChange,
    isOptionsLoading,
    withSearch = true,
    onSearch,
    searchValue,
    className,
    placeholder = 'Не выбрано',
    dropdownStyle = {},
    onLoadMore,
  }: SelectProps): React.ReactElement<SelectProps> => {
    const handleSearch = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onSearch?.(e.target.value);
      },
      [onSearch],
    );

    const [ref, inView] = useInView({
      triggerOnce: false,
    });

    React.useEffect(() => {
      if (onLoadMore) {
        if (inView && !isOptionsLoading) {
          onLoadMore();
        }
      }
    }, [inView, isOptionsLoading]);

    const activeValue = React.useMemo(() => {
      return options.find((item) => item.id === value);
    }, [value, options, isOptionsLoading]);

    return (
      <div className={className}>
        <Select
          className={s['multi-select__select']}
          dropdownStyle={{
            borderRadius: '8px',
            ...dropdownStyle,
          }}
          dropdownRender={() => (
            <>
              {withSearch && (
                <Input
                  className={s['multi-select__search']}
                  placeholder="Поиск"
                  value={searchValue}
                  onChange={handleSearch}
                  suffix={<SearchOutlined className={s['multi-select__search-icon']} />}
                  allowClear
                />
              )}
              <div className={s['multi-select__items-wrapper']}>
                {isOptionsLoading && options.length === 0 && <Spin size="small" />}
                {!isOptionsLoading && options.length === 0 && (
                  <Typography.Text className={s['multi-select__item_placeholder']}>Нет доступных опций</Typography.Text>
                )}

                {onLoadMore &&
                  options.length > 0 &&
                  options.map((option) => (
                    <MultiSelectItem
                      key={option.id}
                      option={option}
                      onChange={onChange}
                      checked={option.id === value}
                      className={s['multi-select__item']}
                    />
                  ))}

                {!isOptionsLoading &&
                  onLoadMore === undefined &&
                  options.map((option) => (
                    <MultiSelectItem
                      key={option.id}
                      option={option}
                      onChange={onChange}
                      checked={option.id === value}
                      className={s['multi-select__item']}
                    />
                  ))}
                {isOptionsLoading && options.length > 0 && <Spin size="small" />}
                {!isOptionsLoading && onLoadMore && options.length > 0 && (
                  <div ref={ref} style={{ height: '10px', marginTop: '20px' }}></div>
                )}
              </div>
            </>
          )}
          placeholder={placeholder}
          value={activeValue ? activeValue.title : null}
        />
      </div>
    );
  },
);

export default React.memo(SelectCustom);

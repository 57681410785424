import { MapYandex2 } from 'components/Map/Map2';
import { observer } from 'mobx-react-lite';
import React from 'react';

const YandexMapPage = (): React.ReactElement => {
  return (
    <div>
      <MapYandex2 />
    </div>
  );
};

export default observer(YandexMapPage);

import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link, useMatch } from 'react-router-dom';

import { routerUrls } from 'config/routes';
import { useRootStore } from 'stores/RootStore';

import ProfileIconButton from '../ProfileIconButton';

import s from './ProfileMenu.module.scss';

type DropdownItemsProps = {
  isProfileRoute: boolean;
  onLogout: VoidFunction;
};

type ProfileMenuProps = {
  className?: string;
};

const getDropdownItems = (props: DropdownItemsProps): MenuProps['items'] => [
  {
    key: '43',
    label: (
      <Link className={s.link} to={routerUrls.profileRobot.create()}>
        Умный поиск
      </Link>
    ),
  },
  {
    key: '4',
    label: (
      <Link className={s.link} to={routerUrls.tariffs.create()}>
        Тарифы
      </Link>
    ),
  },
  {
    key: '7',
    label: (
      <Link className={s.link} to={routerUrls.profileServices.create('escort')}>
        Услуги
      </Link>
    ),
  },
  {
    key: '2',
    label: (
      <button className={cn(s['profile-menu__button'], s['profile-menu__button_logout'])} onClick={props.onLogout}>
        <span className={cn(s['profile-menu__logout-text'])}>Выйти</span>
      </button>
    ),
  },
];

const ProfileMenu = ({ className }: ProfileMenuProps): React.ReactElement<ProfileMenuProps> => {
  const { authStore, userStore } = useRootStore();
  const isProfileRoute = Boolean(useMatch(routerUrls.profile.mask));

  return (
    <Dropdown
      overlayClassName={cn(s['profile-menu'], className)}
      menu={{
        items: getDropdownItems({
          isProfileRoute,
          onLogout: authStore.logout,
        }),
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      aria-label="Меню профиля"
      align={{ offset: [60, 20] }}
    >
      <ProfileIconButton color="blue" user={userStore.user} />
    </Dropdown>
  );
};

export default observer(ProfileMenu);

import { useModalFilterText } from 'components/ModalText/ModalText.context';
import { useMemo } from 'react';
import { useRootStore } from 'stores/RootStore';

export const useUserGetTariffInfo = () => {
  const { userStore, tariffsStore } = useRootStore();
  const info = useMemo(() => {
    if (!userStore.authorized) {
      return {
        activeUserTariff: null,
        authorized: false,
        tariffInfo: null,
      };
    }
    const tariff = tariffsStore.tariffs.items.find((item) => item.id === userStore.activeUserTariff?.tariff_id) || null;
    return {
      activeUserTariff: userStore.activeUserTariff,
      authorized: userStore.authorized,
      tariffInfo: tariff,
    };
  }, [
    userStore.authorized,
    userStore.activeUserTariff,
    tariffsStore.loadingStage.isLoading,
    tariffsStore.tariffs.items,
  ]);

  return info;
};

export const usePopupFilterAuth = () => {
  const { toggleOpen } = useModalFilterText();
  const { userStore } = useRootStore();
  const tariff = useUserGetTariffInfo();

  const isPopup = useMemo(() => {
    if (!userStore.authorized) {
      return true;
    } else if (userStore.activeUserTariff) {
      if (tariff.tariffInfo === null || tariff?.tariffInfo?.robot_assistant === false) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }, [userStore.authorized, userStore.activeUserTariff, tariff]);

  const onShowPopup = isPopup ? () => toggleOpen(true) : undefined;

  return {
    onShowPopupFiltersAuth: onShowPopup,
    isShowPopupFiltersAuth: isPopup,
  };
};

import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useLotPageMultiStore } from 'stores/LotPageStore';
import { PageContainer } from 'components/PageContainer';
import { notification, Space } from 'antd';
import LotImagesCarousel from '../LotImagesCarousel';
import MainInfo from '../MainInfo';
import LotTimeline from '../LotTimeline';
import { Wrapper } from 'components/Wrapper';
import DocsSection from '../CommonInfo/DocsSection';
import { ElementId } from 'config/ids';
import PropertySection from '../CommonInfo/PropertySection';
import CommonSection from '../CommonInfo/CommonSection';
import sPage from '../LotPage.module.scss';
import s from '../CommonInfo/CommonInfo.module.scss';
import cn from 'classnames';
import Navigation from '../CommonInfo/Navigation';
import { ButtonIcon } from 'components/ButtonIcon';
import { CheckCircleFilled, FilePdfFilled, WarningFilled } from '@ant-design/icons';
import { isLotValueModel } from 'utils/lot';
import { useRootStore } from 'stores/RootStore';

const key = 'pdf-notif';

export const SaveLotPdf = observer(() => {
  const { lot } = useLotPageMultiStore();
  const { userStore } = useRootStore();
  const [loading, setLoading] = React.useState(false);
  const [api, contextHolder] = notification.useNotification();
  const refPageOne = useRef<HTMLDivElement | null>(null);
  const refPageTwo = useRef<HTMLDivElement | null>(null);
  const dataLotInfo = React.useMemo(() => {
    if (isLotValueModel(lot)) {
      return {
        common: {
          title: lot.value.title,
          description: lot.value.description,
          lotPageAddress: lot.value.lotPageAddress,
          objectType: lot.value.objectType,
          auctionOrganizer: lot.value.auctionOrganizer?.name,
          auctionType: lot.value.auctionType,
        },
        property: {
          totalAreaFormatted: lot.value.totalAreaFormatted,
          floor: lot.value.floor,
          objectType: lot.value.objectType,
        },
      };
    }

    return {
      common: {
        title: lot.value.title,
        description: 'Зарегистрируйтесь для просмотра',
        lotPageAddress: 'Зарегистрируйтесь для просмотра',
        objectType: lot.value.objectType,
        auctionOrganizer: 'Зарегистрируйтесь для просмотра',
        auctionType: 'Зарегистрируйтесь для просмотра',
      },
      property: {
        totalAreaFormatted: 'Зарегистрируйтесь для просмотра',
        floor: 'Зарегистрируйтесь для просмотра',
        objectType: lot.value.objectType,
      },
    };
  }, [userStore.authorized, lot]);

  const notificationShow = (title: string, description: string, isError?: boolean) => {
    api.info({
      message: title,
      description: description,
      placement: 'topRight',
      icon: isError ? <WarningFilled style={{ color: 'red' }} /> : <CheckCircleFilled style={{ color: 'green' }} />,
      key,
    });
  };

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      if (refPageOne.current && refPageTwo.current) {
        const pdfOne = refPageOne.current;
        const doc = new jsPDF('p', 'mm');
        const imgWidth = 208;

        const canvasPageOne = await html2canvas(pdfOne);
        const imgHeight = (canvasPageOne.height * imgWidth) / canvasPageOne.width;
        doc.addImage(canvasPageOne, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');

        const canvasPageTwo = await html2canvas(refPageTwo.current);
        const imgHeightTwo = (canvasPageTwo.height * imgWidth) / canvasPageTwo.width;
        doc.addPage();
        doc.addImage(canvasPageTwo, 'PNG', 0, 0, imgWidth, imgHeightTwo, '', 'FAST');

        setLoading(false);
        doc.save(lot.value.id + '.pdf');
      } else {
        throw 'Content empty';
      }
    } catch (error) {
      notificationShow('Ошибка!', 'Повторите через время.', true);
      console.log('CANVAS ERROR', JSON.stringify(error));
    }
  }, [loading, refPageOne, refPageTwo, lot]);

  return (
    <div>
      {contextHolder}
      <ButtonIcon
        Icon={FilePdfFilled}
        id={'pdf-btn'}
        className={cn(sPage['save-button'])}
        onClick={onSave}
        title="Выгрузить страницу лота в PDF"
      >
        <span className={sPage['save-button-icon']}>Выгрузить страницу лота в PDF</span>
      </ButtonIcon>
      <div className={'inner-pdf'}>
        <div className="page-container-pdf" ref={refPageOne}>
          <PageContainer className={cn(s['lot-page'], 'pdf')}>
            <Space size="large" className={cn(sPage['lot-page__content'], sPage['pdf'])}>
              <LotImagesCarousel className={cn(sPage['lot-page__carousel'], sPage['pdf'])} />
              <MainInfo showPrintPdf={false} />
            </Space>
            <LotTimeline className={sPage['lot-page__timeline']} />
          </PageContainer>
          <Wrapper
            id={ElementId.lotPage}
            className={cn(sPage['lot-page__common-info'], s.info, s.info__info__desktop__only)}
          >
            <Navigation onScroll={() => {}} grid={true} />
            <Space className={cn(s.info__content, s.info__info__desktop__only)} direction="vertical" size={0}>
              <CommonSection
                lot={dataLotInfo.common}
                id={ElementId.lotPageCommonDesktop}
                print={true}
                hide={!userStore.authorized}
              />
            </Space>
          </Wrapper>
        </div>
        <div className="page-container-pdf" ref={refPageTwo}>
          <Wrapper
            id={ElementId.lotPage}
            className={cn(sPage['lot-page__common-info'], s.info, s.info__info__desktop__only)}
          >
            <div />
            <Space className={cn(s.info__content, s.info__info__desktop__only)} direction="vertical" size={0}>
              <PropertySection
                lot={dataLotInfo.property}
                id={ElementId.lotPagePropertyCharacteristicsDesktop}
                print={true}
                hide={!userStore.authorized}
              />
              <DocsSection lot={lot.value} id={ElementId.lotPageDocsDesktop} auth={userStore.authorized} print={true} />
            </Space>
          </Wrapper>
        </div>
      </div>
    </div>
  );
});

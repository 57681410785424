import { E164Number } from 'libphonenumber-js/core';
import { action, computed, makeObservable, runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { AnalyticsEvent } from 'entities/analytics';
import { UserServer } from 'entities/user';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ToggleModel } from 'models/ToggleModel';
import { UserModel } from 'models/UserModel';
import { ValueModel } from 'models/ValueModel';
import { IAnalyticsStore } from 'stores/AnalyticsStore';
import { IUserStore } from 'stores/UserStore';
import { BaseResponse } from 'types/meta';
import { apiCustom, apiErrorToString } from 'utils/api';
import { emailValidator, phoneStringValidator, phoneValidator, stringLengthValidator } from 'utils/validator';

import { IAuthStore } from './types';
import { ymEvents } from 'utils/ym';

export enum AuthStep {
  initial = 'initial',
  confirm = 'confirm',
  success = 'success',
  registration_success = 'registration_success',
}
type AuthStoreParams = {
  userStore: IUserStore;
  analyticsStore: IAnalyticsStore;
  profileServicesReset?: () => void;
};

const SECONDS_TO_GET_NEW_CODE = 30;

export class AuthStore implements IAuthStore {
  readonly mode: ValueModel<'login' | 'register'> = new ValueModel<'login' | 'register'>('login');
  readonly authType: ValueModel<'phone' | 'email'> = new ValueModel<'phone' | 'email'>('phone');
  readonly email: ValueModel<string> = new ValueModel<string>('', [emailValidator]);
  readonly phone: ValueModel<E164Number | null> = new ValueModel<E164Number | null>(null, [
    phoneValidator,
    phoneStringValidator,
  ]);
  readonly code: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 4, maxLength: 6 }),
  ]);

  readonly authStep: ValueModel<AuthStep> = new ValueModel<AuthStep>(AuthStep.initial);
  readonly timer: ValueModel<ReturnType<typeof setInterval> | null> = new ValueModel<ReturnType<
    typeof setInterval
  > | null>(null);
  readonly secondsCounter: ValueModel<number> = new ValueModel(SECONDS_TO_GET_NEW_CODE);
  readonly firstName: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 1, maxLength: 100 }),
  ]);
  readonly lastName: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 1, maxLength: 100 }),
  ]);
  readonly patronymic: ValueModel<string> = new ValueModel<string>('');
  readonly isFirstVisit: ValueModel<boolean> = new ValueModel<boolean>(true);

  readonly popupController: ToggleModel = new ToggleModel();

  readonly userStore: IUserStore;
  readonly analyticsStore: IAnalyticsStore;

  readonly registerStage: LoadingStageModel = new LoadingStageModel();
  readonly codeLoadingStage: LoadingStageModel = new LoadingStageModel();
  readonly checkCodeStage: LoadingStageModel = new LoadingStageModel();
  readonly logoutStage: LoadingStageModel = new LoadingStageModel();
  readonly authorizingStage: LoadingStageModel = new LoadingStageModel();
  readonly dataCode: ValueModel<string> = new ValueModel<string>('');
  private profileServicesReset?: () => void;
  constructor({ userStore, analyticsStore, profileServicesReset }: AuthStoreParams) {
    this.userStore = userStore;
    this.analyticsStore = analyticsStore;
    this.profileServicesReset = profileServicesReset;
    makeObservable(this, {
      setStep: action.bound,
      register: action.bound,
      checkCode: action.bound,
      startSecondsCounter: action.bound,
      getCode: action.bound,
      resetTimer: action.bound,
      reset: action.bound,
      logout: action.bound,
      authorize: action.bound,

      isAuthorizing: computed,
    });
  }

  get isAuthorizing(): boolean {
    return this.authorizingStage.isLoading;
  }

  setStep(value: AuthStep) {
    this.authStep.change(value);
  }

  changePhoneNumber = () => {
    this.setStep(AuthStep.initial);
    this.resetTimer();
  };

  isAuthStoreKey = (key: string): key is keyof AuthStore => {
    return key in this.toObjectData();
  };

  isValueModel(value: unknown): value is ValueModel {
    return value instanceof ValueModel;
  }

  async getAuthorizeUser(): Promise<BaseResponse<UserServer, string>> {
    if (this.authorizingStage.isLoading) {
      return {
        isError: true,
        data: 'Запрос уже был отправлен',
      };
    }

    this.authorizingStage.loading();

    const response = await apiCustom<UserServer>({
      url: apiUrls.user,
      method: 'GET',
    });

    if (response.isError) {
      this.authorizingStage.error();

      return { isError: true, data: apiErrorToString(response.data) };
    }

    runInAction(() => {
      this.authorizingStage.success();
      this.userStore.setUser(UserModel.fromJson(response.data));
      this.analyticsStore.trackEvent({ event: AnalyticsEvent.userLogin });
    });

    return { isError: false, data: response.data };
  }

  async authorize(): Promise<BaseResponse<string, string>> {
    if (this.authorizingStage.isLoading || this[this.authType.value].validate()) {
      return {
        isError: true,
        data: 'Запрос уже был отправлен',
      };
    }

    this.authorizingStage.loading();

    const response = await apiCustom<{ data: string }>({
      url: apiUrls[this.authType.value].send,
      method: 'POST',
      data: {
        [this.authType.value]:
          this.authType.value === 'phone'
            ? '+' + this[this.authType.value]?.value?.replace(/[^0-9]/g, '')
            : this[this.authType.value].value,
      },
    });

    if (response.isError) {
      this.authorizingStage.error();
      return { isError: true, data: apiErrorToString(response.data) };
    }
    runInAction(() => {
      this.dataCode.change(response.data.data);
      this.authorizingStage.success();
      this.setStep(AuthStep.confirm);
      this.startSecondsCounter();
      this.isFirstVisit.change(false);
      this.analyticsStore.trackEvent({ event: AnalyticsEvent.userLogin });
    });

    return { isError: false, data: response.data.data };
  }

  async register(): Promise<BaseResponse<string, string>> {
    if (this.authType.value === 'phone') {
      ymEvents.personTel();
    } else {
      ymEvents.personChekEmail();
    }

    if (
      this[this.authType.value].validate() ||
      this.firstName.validate() ||
      this.lastName.validate() ||
      this.registerStage.isLoading
    ) {
      return { isError: true, data: 'Запрос уже был отправлен' };
    }

    this.registerStage.loading();

    const preparedData = {
      [this.authType.value]: this[this.authType.value].value,
      first_name: this.firstName.value,
      last_name: this.lastName.value,
      patronymic: this.patronymic.value,
    };

    const response = await apiCustom<{ data: string }>({
      url: apiUrls[this.authType.value].registration,
      method: 'POST',
      data: preparedData,
    });

    if (response.isError) {
      this.registerStage.error();

      if (response.data) {
        Object.entries(response.data).forEach(([key, value]) => {
          if (this.isAuthStoreKey(key) && this.isValueModel(this[key])) {
            this[key].changeError(value.toString());
          }
        });
      }

      return { isError: true, data: apiErrorToString(response.data) };
    }

    runInAction(() => {
      this.dataCode.change(response.data.data);
      this.registerStage.success();
      this.setStep(AuthStep.confirm);
      this.isFirstVisit.change(true);
      this.startSecondsCounter();
    });

    return { isError: false, data: response.data.data };
  }

  startSecondsCounter() {
    if (this.timer.value) {
      clearTimeout(this.timer.value);
    }

    const timer = setInterval(() => {
      this.secondsCounter.change(this.secondsCounter.value > 0 ? this.secondsCounter.value - 1 : 0);

      if (this.secondsCounter.value === 0) {
        clearTimeout(timer);
      }
    }, 1000);

    this.timer.change(timer);
  }

  async checkCode(isRegistration: boolean): Promise<BaseResponse<UserServer, string>> {
    if (this.authType.value === 'phone') {
      ymEvents.personChekOk();
    } else {
      ymEvents.personChekEmail();
    }

    if (this.checkCodeStage.isLoading || this.code.validate()) {
      return {
        isError: true,
        data: 'Запрос уже был отправлен',
      };
    }

    this.checkCodeStage.loading();

    const response = await apiCustom<UserServer, string, string[]>({
      url: apiUrls[this.authType.value].confirm,
      method: 'POST',
      data: {
        [this.authType.value]: this[this.authType.value].value,
        code: this.code.value,
        data: this.dataCode.value,
      },
    });

    if (response.isError) {
      this.checkCodeStage.error();

      if (response.data) {
        Object.entries(response.data).forEach(([key, value]) => {
          if (key === 'attempts') {
            this.code.changeError(`Неверный код. Осталось попыток: ${value.toString()}`);
          }
        });
      }

      return { isError: true, data: apiErrorToString(response.data) };
    }

    runInAction(() => {
      this.checkCodeStage.success();
      this.setStep(isRegistration ? AuthStep.registration_success : AuthStep.success);
      this.userStore.setUser(UserModel.fromJson(response.data));
      this.analyticsStore.trackEvent({ event: AnalyticsEvent.userLogin });
    });

    return { isError: false, data: response.data };
  }

  async getCode(): Promise<BaseResponse<{ timeout: number; data: string }, string>> {
    if (this.codeLoadingStage.isLoading) {
      return { isError: true, data: 'Запрос уже был отправлен' };
    }

    this.code.reset();
    this.codeLoadingStage.loading();

    const response = await apiCustom<{ timeout: number; data: string }>({
      url: apiUrls[this.authType.value].send,
      method: 'POST',
      data: {
        [this.authType.value]: this[this.authType.value].value,
      },
    });

    if (response.isError) {
      this.codeLoadingStage.error();

      return { isError: true, data: apiErrorToString(response.data) };
    }

    runInAction(() => {
      this.dataCode.change(response.data.data);
      this.codeLoadingStage.success();
      this.secondsCounter.change(response.data.timeout + 1);
      this.startSecondsCounter();
    });

    return { isError: false, data: response.data };
  }

  async logout(): Promise<BaseResponse> {
    if (this.logoutStage.isLoading) {
      return { isError: true };
    }

    this.dataCode.change('');

    this.logoutStage.loading();

    const response = await apiCustom({
      url: apiUrls.logout,
      method: 'GET',
    });

    if (response.isError) {
      this.logoutStage.error();
      return { isError: true };
    }

    runInAction(() => {
      this.logoutStage.success();
      this.userStore.resetUser();
      this.profileServicesReset && this.profileServicesReset();
      this.reset();
    });

    return { isError: false };
  }

  resetTimer() {
    if (this.timer.value) {
      clearTimeout(this.timer.value);
    }

    this.timer.change(null);
    this.secondsCounter.change(SECONDS_TO_GET_NEW_CODE);
  }

  reset() {
    this.phone.change(null);
    this.code.change('');
    this.setStep(AuthStep.initial);
    this.resetTimer();
  }

  private toObjectData = () => {
    return {
      email: this.email.value,
      phone: this.phone.value,
      code: this.code.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      patronymic: this.patronymic.value,
    };
  };
}

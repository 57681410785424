import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { RangeFilter } from 'components/RangeFilter';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { Checkbox } from 'antd';
import s from './AmountFloors.module.scss';
import { usePopupFilterAuth } from 'hooks/usePopupFilterAuth';

type AmountFloorsProps = {
  className?: string;
};

const AmountFloors = ({ className }: AmountFloorsProps): React.ReactElement<AmountFloorsProps> => {
  const { filters } = useLotListStore();
  const { onShowPopupFiltersAuth, isShowPopupFiltersAuth } = usePopupFilterAuth();

  return (
    <div className={s.floor}>
      <BaseInput className={className} title="Этажность здания">
        <RangeFilter
          from={filters.amountFloors.from.value}
          to={filters.amountFloors.to.value}
          valueMin={filters.amountFloors.valueMin.value}
          valueMax={filters.amountFloors.valueMax.value}
          onChangeMin={filters.amountFloors.valueMin.change}
          onChangeMax={filters.amountFloors.valueMax.change}
          placeholderSufix={{
            from: 'от ' + (filters.amountFloors.from.value || 0),
            to: 'до ' + (filters.amountFloors.to.value || 0),
          }}
          offFilter={onShowPopupFiltersAuth}
        />
      </BaseInput>
      <div className={s.options} onClick={onShowPopupFiltersAuth}>
        <Checkbox
          className={s.checkbox}
          disabled={isShowPopupFiltersAuth}
          onChange={() => {
            if (filters.showUnknownAmountOfFloors.value === null) {
              filters.showUnknownAmountOfFloors.change(true);
            } else {
              filters.showUnknownAmountOfFloors.change(!filters.showUnknownAmountOfFloors.value);
            }
          }}
          checked={filters.showUnknownAmountOfFloors.value || false}
        >
          Этажность не указана
        </Checkbox>
      </div>
    </div>
  );
};

export default observer(AmountFloors);

import * as React from 'react';

import { ElementId } from 'config/ids';
import { LotModel } from 'models/LotModel';

import Section from '../Section';
import SectionBlock from '../SectionBlock';

type CommonSectionProps = {
  lot: {
    title: string;
    description?: string | null;
    lotPageAddress?: string | null;
    objectType: string;
    auctionOrganizer?: string | null;
    auctionType: string;
  };
  id: ElementId;
  hide?: boolean;
  print?: boolean;
};

const CommonSection: React.FC<CommonSectionProps> = ({ lot, id, hide = true, print = false }) => {
  return (
    <Section id={id} title="Общие сведения" className={'section-navigation ' + id} grid={print}>
      <SectionBlock title="Предмет торгов (наименование лота)" description={lot.title} hide={false} />
      {lot.description && <SectionBlock title="Описание лота" description={lot.description} hide={hide} />}
      {lot.lotPageAddress && <SectionBlock title="Адрес лота" description={lot.lotPageAddress} hide={hide} />}
      <SectionBlock title="Категория объекта" description={lot.objectType} hide={false} />
      {lot.auctionOrganizer && <SectionBlock title="Организатор" description={lot.auctionOrganizer} hide={hide} />}
      <SectionBlock title="Вид договора" description={lot.auctionType} hide={hide} />
    </Section>
  );
};

export default React.memo(CommonSection);

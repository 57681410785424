import React, { useEffect, useRef, useState } from 'react';
import dataJson from './data_test.json';
import s from './Map.module.scss';
import { Button } from 'antd';
import { pintYandexMap } from './paint';
import { SelectCustom } from 'components/Select';
import ymaps from 'yandex-maps';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

const stylePaint = {
  strokeColor: '#ff00ff',
  strokeOpacity: 0.7,
  strokeWidth: 3,
  fillColor: '#ff00ff',
  fillOpacity: 0,
};

function filterRadiusFunction(geometry: any, reset: boolean) {
  if (reset) {
    return (_: unknown) => true;
  }
  return function (obj: any) {
    var content: [number, number] = obj.geometry.coordinates;
    return geometry ? geometry?.contains(content) || false : false;
  };
}

function calculateDistance(point1: any, point2: any) {
  return window.ymaps.coordSystem.geo.getDistance(point1, point2);
}

export const MapYandex2 = () => {
  const [openRoute, setOpenRoute] = useState<boolean>(false);
  const [radius, setRadius] = useState<null | undefined | number>(null);
  const [layer, setLayer] = useState<null | undefined | number>(null);
  const refMap = useRef<ymaps.Map | null>(null);
  const refObjectManager = useRef<ymaps.ObjectManager | null>(null);
  const refPaintPoligon = useRef<ymaps.Polygon | null>(null);
  const refCircleRadius = useRef<ymaps.Circle | null>(null);
  const refPaintProcess = useRef<any | null>(null);
  const refPaintOn = useRef<boolean>(false);
  const rulerPolyline = useRef<null | ymaps.Polyline>(null);
  const rulerActive = useRef(false);
  const rulerPoints = useRef<any>([]);
  const refLabelsRule = useRef<any>([]);

  const changeRadius = (radius_value: number | null) => {
    setRadius(radius_value);

    if (refPaintPoligon.current && refPaintPoligon) {
      refPaintOn.current = false;
      refMap.current?.geoObjects.remove(refPaintPoligon.current);
    }

    if (radius === radius_value) {
      if (
        refCircleRadius.current !== null &&
        refMap !== null &&
        refMap.current !== null &&
        refObjectManager.current !== null
      ) {
        refMap.current.geoObjects.remove(refCircleRadius.current);
        refCircleRadius.current = null;
        refObjectManager.current.setFilter(filterRadiusFunction(null, true));
        setRadius(null);
      }
    } else {
      if (refMap && refMap.current && refObjectManager.current) {
        if (refCircleRadius.current) {
          refMap.current.geoObjects.remove(refCircleRadius.current);
          refCircleRadius.current = new window.ymaps.Circle([[55.76, 37.64], radius_value], null, {
            draggable: false,
            fillOpacity: 0,
          });
          refMap.current.geoObjects.add(refCircleRadius.current!);
        } else {
          refCircleRadius.current = new window.ymaps.Circle([[55.76, 37.64], radius_value], null, {
            draggable: false,
            fillOpacity: 0,
          });
          refMap.current.geoObjects.add(refCircleRadius.current!);
        }
        refObjectManager.current.setFilter(
          filterRadiusFunction(refCircleRadius.current ? refCircleRadius.current.geometry : null, false),
        );
      }
    }
  };

  const onPaintStart = (e: any) => {
    if (refPaintOn.current) {
      if (refPaintPoligon.current && refPaintPoligon) {
        refMap.current?.geoObjects.remove(refPaintPoligon.current);
        refObjectManager?.current?.setFilter(filterRadiusFunction(null, true));
      }
      refPaintProcess.current = window.ymaps.ext.paintOnMap(refMap.current, e, { style: stylePaint });
    }
  };

  const onPaintEnd = (e: any) => {
    if (refPaintProcess.current) {
      const coordinates = refPaintProcess.current.finishPaintingAt(e);
      refPaintProcess.current = null;
      refPaintPoligon.current = new window.ymaps.Polygon([coordinates], {}, stylePaint);
      refPaintPoligon.current?.geometry;
      refMap.current?.geoObjects.add(refPaintPoligon.current!);
      refObjectManager?.current?.setFilter(filterRadiusFunction(refPaintPoligon.current?.geometry, false));
    }
  };

  const onToggleFullscreen = () => {
    if (refMap.current) {
      if (refMap.current.container.isFullscreen()) {
        refMap.current.container.exitFullscreen();
      } else {
        refMap.current.container.enterFullscreen();
      }
    }
  };

  const updateLabels = () => {
    refLabelsRule.current.forEach(function (label: any) {
      refMap.current?.geoObjects.remove(label);
    });
    refLabelsRule.current = [];
    for (var i = 1; i < rulerPoints.current.length; i++) {
      var distance = calculateDistance(rulerPoints.current[i - 1], rulerPoints.current[i]);
      var label = new window.ymaps.Placemark(
        [rulerPoints.current[i][0], rulerPoints.current[i][1]],
        {
          iconContent: distance.toFixed(2) + ' м',
        },
        {
          preset: 'islands#blueStretchyIcon',
        },
      );
      refLabelsRule.current.push(label);
      refMap.current?.geoObjects.add(label);
    }
  };

  const onUserGeoPosition = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          refMap.current?.setCenter([latitude, longitude], 15);
        },
        function (error) {
          alert('Не удалось определить ваше местоположение: ' + error.message);
        },
      );
    }
  };

  const onRuler = () => {
    rulerActive.current = !rulerActive.current;
    if (rulerActive.current) {
      rulerPoints.current = [];
      updatePolyline();
      updateLabels();
    } else {
      if (rulerPolyline.current && refMap.current) {
        refMap.current.geoObjects.remove(rulerPolyline.current);
      }
      refLabelsRule.current.forEach((item: any) => {
        refMap.current?.geoObjects.remove(item);
      });
      refLabelsRule.current = [];
      refLabelsRule.current.forEach(function (label: any) {
        refMap.current?.geoObjects.remove(label);
      });
      refLabelsRule.current = [];
    }
  };

  const updatePolyline = () => {
    if (rulerPolyline.current) {
      refMap.current?.geoObjects.remove(rulerPolyline.current);
    }

    if (rulerPoints.current.length > 1) {
      rulerPolyline.current = new window.ymaps.Polyline(
        rulerPoints.current,
        {},
        {
          strokeColor: '#0000FF',
          strokeWidth: 4,
        },
      );
      refMap.current?.geoObjects.add(rulerPolyline.current!);
    }
  };

  const onLayerSelect = (value: number | null) => {
    if (refMap.current) {
      if (value === layer || value === null) {
        setLayer(null);
        refMap.current.setType('yandex#map'); // Схема
      } else {
        if (value === 1) {
          refMap.current.setType('yandex#map'); // Схема
        } else if (value === 2) {
          refMap.current.setType('yandex#satellite'); // Спутник
        } else if (value === 3) {
          refMap.current.setType('yandex#hybrid'); // Гибрид
        }

        setLayer(value);
      }
    }
  };

  const onOpenRoute = () => {
    const open = !openRoute;
    const panel = refMap?.current?.controls.get('routePanelControl') as any;
    if (open) {
      panel.options.set('visible', true);
    } else {
      panel.options.set('visible', false);
    }
    setOpenRoute(open);
  };

  const initMap = () => {
    refMap.current = new window.ymaps.Map(
      'map',
      {
        center: [55.76, 37.64],
        zoom: 10,
        controls: [],
      },
      {
        searchControlProvider: 'yandex#search',
      },
    );

    refObjectManager.current = new window.ymaps.ObjectManager({
      clusterize: true,
      gridSize: 64,
    });

    if (refObjectManager.current && refMap.current) {
      refObjectManager.current.add(dataJson);
      refMap.current.geoObjects.add(refObjectManager.current);

      refMap.current?.controls.add(
        'routePanelControl' as any,
        {
          maxWidth: 300,
          visible: false,
          position: {
            top: 50,
            left: 10,
          },
        } as any,
      );

      refMap.current.events.add('mousedown', function (e) {
        if (refPaintOn.current) onPaintStart(e);
      });

      refMap.current.events.add('mouseup', (e) => {
        if (refPaintOn.current) onPaintEnd(e);
      });

      refMap.current.events.add('click', (e) => {
        const points = [...rulerPoints.current];
        rulerPoints.current = points;

        if (rulerActive.current) {
          var coords = e.get('coords');
          points.push(coords);

          updatePolyline();
          updateLabels();
        }
      });
    }
  };

  useEffect(() => {
    //TODO add removeListener map and events
    if (window && document && refMap.current === null) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.src = '//api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=1f891890-3ee7-4fde-99b8-23f0b3786fa3';
      body.appendChild(script);
      script.addEventListener('load', () => {
        if ('ymaps' in window) {
          window.ymaps.modules.define(
            'ext.paintOnMap',
            ['meta', 'util.extend', 'pane.EventsPane', 'Event'],
            pintYandexMap,
          );
          window.ymaps.ready(['ext.paintOnMap']).then(initMap).catch(console.error);
        }
      });
    }
  }, []);

  return (
    <div className={s.map__wrap}>
      <div className={s.map__actions__top}>
        <div className={s.map__actions__left}>
          <div style={{ width: 240 }} className={s.map__action__radius}>
            <SelectCustom
              value={radius}
              placeholder="Радиус"
              options={[
                { id: 500, title: '500' },
                { id: 1000, title: '1000' },
                { id: 10000, title: '10000' },
                { id: 30000, title: '30000' },
              ]}
              onChange={(id) => {
                changeRadius(id);
              }}
              isOptionsLoading={false}
              withSearch={false}
            />
          </div>
          <div>
            <SelectCustom
              value={radius}
              placeholder="Слои"
              options={[
                { id: 1, title: 'Схема' },
                { id: 2, title: 'Спутник' },
                { id: 3, title: 'Гибрид' },
              ]}
              onChange={(id) => {
                onLayerSelect(id);
              }}
              isOptionsLoading={false}
              withSearch={false}
            />
          </div>
          <div className={s.map__action__paint}>
            <Button
              onClick={() => {
                refPaintOn.current = !refPaintOn.current;
              }}
            >
              Paint radius
            </Button>
          </div>
          <div className={s.map__action__paint}>
            <Button onClick={onOpenRoute}>Route</Button>
          </div>
        </div>

        <div className={s.map__actions__right}>
          <div>
            <Button onClick={onToggleFullscreen}>Full screen</Button>
          </div>
        </div>
      </div>

      <div className={s.map__actions__bottom}>
        <div></div>
        <div></div>
        <div className={s.map__actions__bottom__end}>
          <Button onClick={onRuler}>Ruler</Button>
          <Button onClick={onUserGeoPosition}>Geo position</Button>
        </div>
      </div>

      <div className={s.map__zoom}>
        <Button
          onClick={() => {
            if (refMap && refMap.current) {
              refMap.current.setZoom(refMap.current.getZoom() - 1, { checkZoomRange: true });
            }
          }}
        >
          -
        </Button>
        <Button
          onClick={() => {
            if (refMap && refMap.current) {
              refMap.current.setZoom(refMap.current.getZoom() + 1, { checkZoomRange: true });
            }
          }}
        >
          +
        </Button>
      </div>

      <div id="map" className={s.map}></div>
    </div>
  );
};

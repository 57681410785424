import { Button, Input, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { useRootStore } from 'stores/RootStore';
import { pluralizePreselected } from 'utils/pluralizer';

import s from './ConfirmStep.module.scss';
import { useSearchParams } from 'react-router-dom';

const ConfirmStep = () => {
  const { authStore } = useRootStore();
  const [search, setSearchParams] = useSearchParams();
  const isRegistration = Boolean(search.get('registration'));
  const { code, authType, checkCode, secondsCounter, getCode, checkCodeStage, changePhoneNumber, popupController } =
    authStore;

  const isGetCodeTimeOut = secondsCounter.value === 0;

  const handleCodeChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      code.change(e.target.value);
    },
    [code],
  );

  const onCheckCode = () => {
    checkCode(isRegistration).then((res) => {
      if (!res.isError) {
        setSearchParams({});
      }
    });
  };

  useKeyboardEvent('Enter', checkCode, popupController.value);

  return (
    <Space className={s.confirm__step} direction="vertical" size={24}>
      <div>
        <Typography.Text className={s.confirm__step__title}>
          Мы отправили шестизначный код вам {authType.value === 'phone' ? 'по смс' : 'на почту'}. Введите его в поле
          ниже.{' '}
        </Typography.Text>
        <Typography.Link className={s.confirm__step__link} onClick={changePhoneNumber}>
          Изменить {authType.value === 'phone' ? 'номер телефона' : 'почту'}
        </Typography.Link>
      </div>
      <Space className={s.confirm__step__space} direction="vertical" size={12}>
        <Input
          className={s.confirm__step__input}
          onChange={handleCodeChange}
          value={code.value}
          type="number"
          size="large"
          placeholder="xxx-xxx"
          status={code.error ? 'error' : undefined}
        />
        {code.isError ? (
          <Typography.Text className={s.confirm__step__text}>
            Неверный код.{' '}
            <Typography.Link className={s.confirm__step__timer__link} onClick={getCode}>
              Отправить новый код
            </Typography.Link>{' '}
            или{' '}
            <Typography.Link className={s.confirm__step__timer__link} onClick={changePhoneNumber}>
              изменить {authType.value === 'phone' ? 'номер телефона' : 'почту'}
            </Typography.Link>
          </Typography.Text>
        ) : !isGetCodeTimeOut ? (
          <Typography.Text className={s.confirm__step__text}>
            Новый код можно отправить через{' '}
            <Typography.Text className={s.confirm__step__timer}>
              {pluralizePreselected(secondsCounter.value, 'секунда', 'venative')}
            </Typography.Text>
          </Typography.Text>
        ) : (
          <Typography.Link className={s.confirm__step__timer__link} onClick={getCode}>
            Отправить новый код
          </Typography.Link>
        )}
        <Button
          onClick={onCheckCode}
          type="primary"
          className={s.confirm__step__button}
          loading={checkCodeStage.isLoading}
          disabled={code.value === null}
        >
          Подтвердить {authType.value === 'phone' ? 'телефон' : 'почту'}
        </Button>
      </Space>
    </Space>
  );
};

export default observer(ConfirmStep);

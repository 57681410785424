import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProfileListType } from 'entities/lotFilters';
import { DEFAULT_EMPTY_TEXT } from 'pages/LotsListPage/LotsList/LotsList';
import { LotsList } from '../../LotsList';
import { emptyListPlaceholderOptions } from 'stores/LotListProfileStore/LotListProfileFiltersModel/config';
import { Empty, Pagination } from 'antd';
import s from '../../FavoritesPage.module.scss';
import { getCurrentPage } from 'utils/page';
import { useLotListProfileFavoriteStore } from 'stores/LotListProfileFavoriteStore/context';
import { useUserGetTariffInfo } from 'hooks/usePopupFilterAuth';
import { Link } from 'react-router-dom';
import { RouteFullPath } from 'config/routes';

type FavoritesListProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const FavoritesList = ({ className }: FavoritesListProps): React.ReactElement<FavoritesListProps> => {
  const refWrap = React.useRef<HTMLDivElement | null>(null);
  const { favoriteLots, filters } = useLotListProfileFavoriteStore();
  const isEmpty = favoriteLots.isEmpty && filters.isEmptySideFilters && !favoriteLots.loadingStage.isLoading;
  const emptyText = isEmpty ? emptyListPlaceholderOptions[ProfileListType.favorites] : DEFAULT_EMPTY_TEXT;
  const tariffUser = useUserGetTariffInfo();

  return (
    <div ref={refWrap} className={className}>
      {tariffUser.tariffInfo && tariffUser.tariffInfo.lot_favorite ? (
        <LotsList lots={favoriteLots} emptyText={emptyText} />
      ) : (
        <Empty
          className={s['lots-list__empty']}
          description={<Link to={RouteFullPath.tariffs}>Оформите тариф для просмотра</Link>}
        />
      )}

      {favoriteLots.total.value &&
      favoriteLots.total.value > 10 &&
      tariffUser.tariffInfo &&
      tariffUser.tariffInfo.lot_favorite ? (
        <div className={s.pagination}>
          <Pagination
            current={getCurrentPage(favoriteLots.total.value, filters.offset)}
            total={favoriteLots.total.value || 0}
            onChange={(page) => {
              filters.setPage(page - 1);
              if (refWrap && refWrap.current) window.scrollTo({ behavior: 'smooth', top: refWrap.current.offsetTop });
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default observer(FavoritesList);

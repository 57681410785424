import { Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { LotStatus } from 'entities/lot';
import { useLotPageMultiStore } from 'stores/LotPageStore';

import TimelineStage from './TimelineStage';

import s from './LotTimeline.module.scss';
import { useRootStore } from 'stores/RootStore';
import { TimelineStageModel } from 'models/TimelineStageModel';

type LotTimelineProps = {
  className?: string;
};

const LotTimeline: React.FC<LotTimelineProps> = ({ className }) => {
  const { lot } = useLotPageMultiStore();
  const { userStore } = useRootStore();
  const { status, timelineModel } = lot.value;

  if (status === LotStatus.canceled) {
    return null;
  }

  const timeline = React.useMemo(() => {
    if (userStore.authorized) {
      if (timelineModel.stages.length === 1) {
        return [
          timelineModel.stages[0],
          {
            ...timelineModel.stages[0],
            title: 'Дата окончания приема заявок',
            formattedDate: 'Оформите тариф для просмотра',
          },
          { ...timelineModel.stages[0], title: 'Проведение', formattedDate: 'Оформите тариф для просмотра' },
        ];
      }

      return timelineModel.stages;
    }

    return [
      timelineModel.stages[0],
      {
        ...timelineModel.stages[0],
        title: 'Дата окончания приема заявок',
        formattedDate: 'Оформите тариф для просмотра',
      },
      { ...timelineModel.stages[0], title: 'Проведение', formattedDate: 'Оформите тариф для просмотра' },
    ];
  }, [userStore.authorized, userStore.activeUserTariff]);

  return (
    <div className={cn(className, s.timeline)}>
      <div className={s.timeline__row}>
        {timeline.map((item) => (
          <Typography.Text key={item.title} className={cn(s.timeline__item, s['timeline__item-title'])}>
            {item.title}
          </Typography.Text>
        ))}
      </div>
      <div className={cn(s.timeline__row, s.timeline__row_bottom, !userStore.authorized && s.blur)}>
        {timeline.map((item, index) => (
          <TimelineStage
            key={item.title}
            model={item as TimelineStageModel}
            active={
              userStore.authorized && timelineModel.stages.length > 1
                ? timelineModel.activePhase === item.phase
                : index === 0
            }
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(LotTimeline);

import React from 'react';
import Input, { InputRef } from 'antd/es/input';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import cn from 'classnames';
import s from './MobileSearch.module.scss';
import { observer } from 'mobx-react-lite';
import { ModalGeopgraphy } from '../Filters/components/ModalGeopgraphy/ModalGeopgraphy';
import { PriceSelectMobile } from '../Filters/components/PriceSelectMobile';

export const MobileSearch = observer(() => {
  const { filters } = useLotListStore();
  const refInput = React.useRef<InputRef | null>(null);

  const handleSearchInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filters.search.change(e.target.value);
    },
    [filters.search.change],
  );

  React.useEffect(() => {
    refInput.current?.input?.classList.add('ym-record-keys');
  }, []);

  return (
    <div className={s.card}>
      <div>
        <Input
          ref={refInput}
          className={cn(s['search-bar'])}
          value={filters.search.value}
          onChange={handleSearchInput}
          placeholder="Тип объектов, кадастровый номер"
          size="large"
          allowClear
        />
      </div>
      <div className={s.price__wrap}>
        <PriceSelectMobile />
      </div>
      <div>
        <ModalGeopgraphy />
      </div>
    </div>
  );
});
